var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "privacy-filed-edit" },
    [
      _c("span", { staticClass: "label" }, [_vm._v(" 隐私协议 ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _c(
            "vh-radio",
            {
              attrs: { label: "1" },
              model: {
                value: _vm.qData.extension.privacySettings,
                callback: function ($$v) {
                  _vm.$set(_vm.qData.extension, "privacySettings", $$v)
                },
                expression: "qData.extension.privacySettings",
              },
            },
            [_vm._v("展示系统自带协议")]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "vh-radio",
            {
              attrs: { label: "2" },
              model: {
                value: _vm.qData.extension.privacySettings,
                callback: function ($$v) {
                  _vm.$set(_vm.qData.extension, "privacySettings", $$v)
                },
                expression: "qData.extension.privacySettings",
              },
            },
            [_vm._v("自定义协议")]
          ),
          _vm._v(" "),
          _c(
            "vh-button",
            {
              staticClass: "set-btn",
              attrs: { type: "text" },
              on: { click: _vm.openPrivacyDialog },
            },
            [_vm._v("去设置")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "preview" }, [
            _c("span", { staticClass: "prefix" }, [_vm._v(" 预览效果：")]),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.customPrivacy) } }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPrivacyDialog
        ? _c("customPrivacy", {
            ref: "dialog",
            attrs: {
              showPrivacyDialog: _vm.showPrivacyDialog,
              privacyDate: _vm.qData.extension.privacyDate,
            },
            on: {
              "update:showPrivacyDialog": function ($event) {
                _vm.showPrivacyDialog = $event
              },
              "update:show-privacy-dialog": function ($event) {
                _vm.showPrivacyDialog = $event
              },
              savePrivacy: _vm.savePrivacy,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "preview" }, [
      _c("span", { staticClass: "prefix" }, [_vm._v(" 预览效果：")]),
      _vm._v("\n      提交并同意\n      "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://t.e.vhall.com/v3/privacyPolicy",
          },
        },
        [_vm._v("《隐私政策》")]
      ),
      _vm._v("\n      及\n      "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://t.e.vhall.com/v3/privacyUPo",
          },
        },
        [_vm._v("《用户保护协议》")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }