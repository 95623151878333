var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vh-input",
        _vm._b(
          {
            directives: [{ name: "clearEmoij", rawName: "v-clearEmoij" }],
            ref: "el",
            staticClass: "inner_input",
            attrs: {
              placeholder: _vm.$attrs["q-placeholder"],
              type: "textarea",
              autosize: { minRows: 1, maxRows: 20 },
              maxlength: _vm.maxlength,
              "show-word-limit": "",
            },
            on: { blur: _vm.handleBlur, focus: _vm.handleFocus },
            nativeOn: {
              keydown: function ($event) {
                $event.stopPropagation()
                return _vm.handlePushKeyword($event)
              },
            },
            model: {
              value: _vm.inputVal,
              callback: function ($$v) {
                _vm.inputVal = $$v
              },
              expression: "inputVal",
            },
          },
          "vh-input",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }