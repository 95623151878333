import BaseForm from "../common/baseForm";
import {
  editExamUserForm,
  getExamUserForm,
  getExamInitUserForm,
  editExamUserFormOpenStatus,
  submitExamUserInfo,
} from "@/sdk/api/api-exam.js";
import { deepCopy } from "@/common/utils.js";
class Questionnaire extends BaseForm {
  constructor({ source_id, source_type, role }) {
    super(source_id);
    this.source_id = source_id;
    this.source_type = source_type;
    this.guidelines = "";
    this.form_switch = false;
    if (source_id) {
      return this.getQuestionnaire(role);
    }
  }
  //获取问卷
  async getQuestionnaire(role) {
    let exam;
    if (role != 1) {
      exam = await getExamInitUserForm({
        source_id: this.source_id,
        source_type: this.source_type,
      });
    } else {
      exam = await getExamUserForm({
        source_id: this.source_id,
        source_type: this.source_type,
      });
    }

    const { code, data } = exam;
    let examdata = {};
    if (code == 200) {
      examdata = this.translateData(data, 2);
      examdata.guidelines = data.guidelines;
    }
    const examdetail = Object.assign(this, examdata);
    const list = examdetail.detail;
    examdetail.detail = [];
    Array.isArray(list) &&
      list.forEach((q) => {
        this.addQuestion(q.type, q);
      });
    this.setCurIndex(examdetail.detail.length - 1);
    return examdetail;
  }
  //转译前后端数据
  translateData(data, type) {
    const exam = deepCopy(data);
    //前端转服务端
    if (type == 1) {
      exam.extension = data.extension ? JSON.stringify(data.extension) : "{}";
      exam.form_switch = exam.form_switch ? 1 : 0;
      exam.form_data = JSON.stringify(
        exam.detail.map((q) => {
          q.id = "";
          q.extension = JSON.stringify(q.extension);
          q.required = q.required ? "Y" : "N";
          q.verification = q.verification ? "Y" : "N";
          return q;
        })
      );
    } else if (type == 2) {
      exam.extension = data.extension ? JSON.parse(data.extension) : {};
      exam.form_switch = exam.form_switch == 1 ? true : false;
      exam.detail =
        exam.form_data || exam.form_detail
          ? JSON.parse(exam.form_data || exam.form_detail).map((q) => {
              q.status = 2;
              q.extension = JSON.parse(q.extension);
              q.required = q.required == "Y" ? true : false;
              q.verification = q.verification == "Y" ? true : false;
              return q;
            })
          : [];
      exam.form_data = null;
    }
    return exam;
  }
  //创建或保存编辑
  async save() {
    const data = this.translateData(this, 1);
    data.guidelines = this.guidelines;
    return await editExamUserForm(data);
  }
  async submit() {
    const data = {
      source_id: this.source_id,
      source_type: this.source_type,
      verify_code: "",
    };
    const user_detail = {};
    this.detail.forEach((item) => {
      user_detail[item.id] = item.replys;
      if (item.extension.name == 4) {
        data.verify_code = item.code;
      }
    });
    data.user_detail = JSON.stringify(user_detail);
    return await submitExamUserInfo(data);
  }
  async changeOpenStatus({ status, source_id }) {
    return await editExamUserFormOpenStatus({
      source_id: this.source_id || source_id,
      source_type: this.source_type,
      form_switch: status ? 1 : 0,
    });
  }

  //交卷
  submitAnswer() {}
}
export default Questionnaire;
