<template>
  <div class="date-field">
    <vh-date-picker
      v-model="item.replys"
      type="date"
      value-format="yyyy-MM-dd"
      class="full-width"
      placeholder="生日"
    >
    </vh-date-picker>
  </div>
</template>

<script>
/**
 * @description 时间
 */
export default {
  name: "a-date",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.date-field {
  .full-width {
    width: 100%;
  }
}
</style>
