var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("cropper", {
        ref: "webinarCropper",
        on: { cropComplete: _vm.cropComplete, resetUpload: _vm.resetUpload },
      }),
      _vm._v(" "),
      _c(
        "vh-upload",
        {
          ref: "upload",
          staticClass: "drag_upload",
          attrs: {
            action: _vm.url,
            data: _vm.saveImageData,
            "show-file-list": false,
            "on-success": _vm.uploadSuccess,
            "on-change": _vm.changeFile,
            "auto-upload": false,
            headers: _vm.headers,
            name: "resfile",
            accept: "image/jpg,image/jpeg,image/png,image/gif,image/bmp",
          },
        },
        [
          _c("div", { staticClass: "upload_content" }, [
            _vm.imgUrl
              ? _c("div", { staticClass: "img_box" }, [
                  _c("img", { attrs: { src: _vm.imgUrl, alt: "" } }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.imgUrl
              ? _c("div", { staticClass: "upload_cover" }, [
                  _c("p", [
                    _c("i", {
                      staticClass: "vh-form-iconfont vh-form-line-refresh-left",
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("更换")]),
                  ]),
                ])
              : _c("div", { staticClass: "tip" }, [
                  _c("p", [
                    _c("i", {
                      staticClass:
                        "vh-form-iconfont vh-form-a-line-cloudtoupload",
                    }),
                    _vm._v(
                      "\n          建议尺寸：800*450px，小于4M 支持jpg、gif、png、bmp\n        "
                    ),
                  ]),
                ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }