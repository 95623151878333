<template>
  <div class="examination-pattern">
    <label class="title">题型</label>
    <div class="pattern-panel">
      <vh-button
        type="text"
        plain
        :class="['pattern-item', { disabled: qCount == 100 }]"
        v-for="item in questionTypes"
        :key="item.name"
        @click="addQuestion(item)"
      >
        <i :class="['vh-form-iconfont ', item.icon]"></i>
        {{ item.name }}
      </vh-button>
    </div>
  </div>
</template>

<script>
// 题型
export default {
  name: "ExamCreatePatern",
  props: {
    qData: {},
  },
  data() {
    return {
      questionTypes: [
        {
          icon: "vh-form-fill-radio",
          name: "文字单选",
          options: {
            type: "radio",
            info: {
              title: "文字单选",
              score: 10,
              extension: {
                option_layout: 1,
              },
            },
          },
        },
        {
          icon: "vh-form-fill-checkbox",
          name: "文字多选",
          options: {
            type: "checkbox",
            info: {
              title: "文字多选",
              score: 10,
              extension: {
                option_layout: 1,
              },
            },
          },
        },
        {
          icon: "vh-form-fill-jpg1",
          name: "图片单选",
          options: {
            type: "radio",
            info: {
              title: "图片单选",
              score: 10,
              extension: {
                has_img: true,
                img_layout: 1,
                option_layout: 1,
              },
            },
          },
        },
        {
          icon: "vh-form-fill-pictures",
          name: "图片多选",
          options: {
            type: "checkbox",
            info: {
              title: "图片多选",
              score: 10,
              has_img: true,
              extension: {
                has_img: true,
                img_layout: 1,
                option_layout: 1,
              },
            },
          },
        },
      ],
    };
  },
  computed: {
    qCount() {
      return this.qData.detail.length;
    },
  },
  methods: {
    addQuestion(item) {
      if (this.qCount >= 100) {
        return;
      }
      this.$emit("onadd", item.options);
    },
  },
};
</script>

<style lang="scss">
// 左侧题型相关css
.examination-pattern {
  height: 100%;
  width: 144px;
  background: #fff;

  .title {
    box-sizing: border-box;
    display: block;
    padding: 13px 24px;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    font-size: 14px;
    line-height: 22px;
  }
  .pattern-item {
    display: block;
    box-sizing: border-box;
    margin-top: 12px;
    margin-left: 12px !important;
    padding: 0 12px;
    width: 104px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      color: #ddd;
    }
  }
  .icon {
    margin-right: 4px;
  }
  .vh-button--text {
    &:hover,
    &:active,
    &:focus {
      color: #fb2626 !important;
    }
  }
}
</style>
