/**
 * 禁止输入表情
 * @param {*} Vue
 */
export const clearEmoij = {
  bind: (el, binding, vnode, oldVnode) => {
    let regex =
      /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2123-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030|(^\s*)/gi;
    const obj = el.querySelectorAll(".vh-input__inner,.vh-textarea__inner")[0];
    let zclearNoNum = function (e) {
      if (e.target.composing) return;
      let match = regex.exec(obj.value);
      if (match) {
        obj.value = obj.value.replace(
          /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2123-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030|(^\s*)/gi,
          ""
        );
        zclearNoNum(e);
      }
      // 触发v-model的更新
      obj.dispatchEvent(new Event("input"));
    };
    let zblur = function (e) {
      setTimeout(() => {
        let match = regex.exec(obj.value);
        if (match) {
          obj.value = obj.value.replace(
            /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2123-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030|(^\s*)/gi,
            ""
          );
        }
        // 触发v-model的更新
        obj.dispatchEvent(new Event("input"));
      }, 50);
    };
    obj.oninput = zblur;

    function onCompositionStart(e) {
      e.target.composing = true;
    }
    function onCompositionEnd(e) {
      e.target.composing = false;
      obj.dispatchEvent(new Event("input"));
    }
    obj.addEventListener("compositionstart", onCompositionStart);
    obj.addEventListener("compositionend", onCompositionEnd);
  },
};
