<template>
  <div class="vmp-exam-item-comp">
    <!-- 图片题 （selectType: 1单选；2多选。支持单选 or 多选）-->
    <h1 class="vmp-exam-item-title" v-if="item.status == 1">
      <span class="zdy-exam-question-type">{{ $t("exam.1002") }}</span
      >{{ item.sortNum }}.{{ item.title }}
      <strong v-if="item.score > 0"
        >({{ item.score }}{{ $t("exam.1012") }})</strong
      >
    </h1>
    <h1 class="vmp-exam-item-title" v-if="item.status == 2">
      <i v-if="item.required" slot="prefix" class="required">*</i>
      {{ item.title }}：
    </h1>
    <!-- 非瀑布流[题目-答案区域]
           .page-${answerType}  mock/show/release 作答类别
           .question_${item.subject_type} 题目类型
           .max-width  一排一列
           .min-width  一排双列
           .top-bottom 上下排布
           .left-right 左右排布
        -->
    <div class="exam-way__no__waterfallFlow">
      <template v-if="item.detail">
        <vh-radio-group v-model="item.replys">
          <div
            :class="[
              'vmp-exam-info--option',
              `question_${item.extension.has_img ? 'img-text' : 'text'}`,
              `page-${answerType}`,
              `${
                item.extension.option_layout == 1 ? 'max-width' : 'min-width'
              }`,
              `${item.extension.img_layout == 1 ? 'top-bottom' : 'left-right'}`,
              `bg_answer__${getStatus(sonItem)}`,
              `${
                item.replys.includes(sonItem.key) && answerType == 1
                  ? 'active'
                  : ''
              }`,
            ]"
            v-for="(sonItem, sonIndex) in item.detail.list"
            :key="`qt_${index}_son${sonIndex}`"
            @click="lineChecked(item, sonItem)"
          >
            <div
              :class="[
                'vmp-exam-info--box',
                `${
                  item.replys == sonItem.key
                    ? 'text__min__width'
                    : 'text__max__width'
                }`,
              ]"
            >
              <div
                class="vmp-exam-info--img object_fit_1"
                v-if="item.extension.has_img && sonItem.imgUrl != undefined"
              >
                <vh-image
                  v-if="preview"
                  :src="sonItem.imgUrl"
                  :fit="'scale-down'"
                  @click.stop="preivew(sonIndex, srcList)"
                  :z-index="2020"
                >
                </vh-image>
                <vh-image
                  v-else
                  :src="sonItem.imgUrl"
                  :preview-src-list="srcList"
                  :fit="'scale-down'"
                  :z-index="2020"
                >
                </vh-image>
              </div>
              <div class="vmp-exam-info--text">
                <div @click.stop>
                  <vh-radio
                    :label="sonItem.key"
                    :name="`radio_no_${item.id}`"
                    class="zdy-exam-radio"
                    :disabled="answerType == 3"
                  ></vh-radio>
                </div>
                <div
                  :class="[
                    'text--content',
                    `item_${item.id}_option_${sonIndex}`,
                    'position_ellipsis',
                  ]"
                  :ref="`item_${item.id}_option_${sonIndex}`"
                >
                  <!--`position_${sonItem.isMoreHeight || 'normal'}` -->
                  <TextEllipsis
                    :text="sonItem.key + '.' + sonItem.value"
                    class="introduce"
                    textClass="text-ellipsis"
                    :isLimitHeight="sonItem.ellipsisShow"
                    :height="sonItem.maxHeight"
                  >
                    <span
                      v-if="sonItem.ellipsisShow"
                      @click.prevent.stop="
                        $forceUpdate();
                        sonItem.ellipsisShow = false;
                        $forceUpdate();
                      "
                      class="ellipsis-toggle"
                      slot="more"
                    >
                      <strong>...</strong>展开
                    </span>
                    <span
                      slot="after"
                      class="ellipsis-toggle link"
                      v-if="!sonItem.ellipsisShow"
                      @click.prevent.stop="
                        $forceUpdate();
                        sonItem.ellipsisShow = true;
                        $forceUpdate();
                      "
                      ><strong>&nbsp;</strong>收起</span
                    >
                  </TextEllipsis>
                </div>
                <div
                  class="text--icon--inline"
                  v-if="
                    answerType == 3 &&
                    ((item.replys == sonItem.key && sonItem.is_answer == 0) ||
                      sonItem.is_answer == 1)
                  "
                >
                  <img
                    src="https://s3.e.vhall.com/common-static/vhall-form/images/icon_exam_option_close.png"
                    v-if="item.replys == sonItem.key && sonItem.is_answer == 0"
                  />
                  <img
                    src="https://s3.e.vhall.com/common-static/vhall-form/images/icon_exam_option_yes.png"
                    v-show="sonItem.is_answer == 1"
                  />
                </div>
              </div>
              <!-- <span
                class="text--content__position"
                v-if="sonItem && sonItem.isMoreHeight"
                @click.prevent.stop="
                  $forceUpdate();
                  changeStatus(sonItem);
                "
              >
                {{ sonItem.isMoreHeight == "open" ? "收起" : "展开" }}
              </span> -->
            </div>
            <div
              class="text--icon"
              v-if="
                answerType == 3 &&
                ((item.replys == sonItem.key && sonItem.is_answer == 0) ||
                  sonItem.is_answer == 1)
              "
            >
              <img
                src="https://s3.e.vhall.com/common-static/vhall-form/images/icon_exam_option_close.png"
                v-if="item.replys == sonItem.key && sonItem.is_answer == 0"
              />
              <img
                src="https://s3.e.vhall.com/common-static/vhall-form/images/icon_exam_option_yes.png"
                v-show="sonItem.is_answer == 1"
              />
            </div>
          </div>
        </vh-radio-group>
      </template>
    </div>
    <!-- 答案结果 -->
    <div class="vmp-exam-info--question--result" v-if="answerType == 3">
      <h2>{{ $t("exam.1014") }}</h2>
      <div class="question--result--box">
        <div class="question--result--left">
          <p>
            <strong>{{ $t("exam.1015") }} </strong>
            <span>{{ item.correct_answer }}</span>
          </p>
        </div>
        <div class="question--result--center">
          <p
            :class="
              item.replys == item.correct_answer ? 'answer_yes' : 'answer_no'
            "
          >
            <strong>{{ $t("exam.1016") }} </strong
            ><span>{{ item.replys }}</span>
          </p>
        </div>
        <div
          :class="[
            'question--result--right',
            item.replys == item.correct_answer
              ? 'result-border-green'
              : item.replys && item.replys != item.correct_answer
              ? 'result-border-red'
              : 'result-border-gray',
          ]"
        >
          <img
            src="https://s3.e.vhall.com/common-static/vhall-form/images/icon_correct.png"
            alt=""
            v-if="item.replys && item.replys == item.correct_answer"
          />
          <img
            src="https://s3.e.vhall.com/common-static/vhall-form/images/icon_incorrect.png"
            alt=""
            v-else-if="item.replys && item.replys != item.correct_answer"
          />
          <img
            src="https://s3.e.vhall.com/common-static/vhall-form/images/icon_no_answer.png"
            alt=""
            v-else
          />
          <span
            :class="
              item.replys == item.correct_answer
                ? 'color-green'
                : item.replys && item.replys != item.correct_answer
                ? 'color-red'
                : 'color-gray'
            "
          >
            {{
              item.replys == item.correct_answer
                ? $t("exam.1019")
                : item.replys && item.replys != item.correct_answer
                ? $t("exam.1020")
                : $t("exam.1009")
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextEllipsis from "@ui/components/common/textEllipsis.vue";
export default {
  name: "a-radio",
  data() {
    return {
      radioVal: "",
      srcList: [],
    };
  },
  components: {
    TextEllipsis,
  },
  props: {
    preview: {
      default: false,
    },
    item: {
      required: true,
      type: Object,
      default: () => {},
    },
    index: {
      required: true,
      type: Number,
      default: 0,
    },
    // 是否做答模式
    answerType: {
      default: 1,
    },
  },
  watch: {
    item: {
      handler() {
        this.initComp();
      },
      // immediate: true,
    },
    "item.replys": function (val, old) {
      if (this.answerType == 1) {
        this.item.setAnswer(val);
      }
    },
  },
  beforeCreate() {},
  created() {
    this.initComp();
  },
  methods: {
    lineChecked(item, sonItem) {
      if (this.answerType == 3) return;
      item.replys = sonItem.key;
    },
    // 标记题目-选项 是否需要 展开or收缩
    setIsMoreHeight() {
      // img_layout: 1, // 1(上图下文)；2(左图右文)
      // option_layout: 1, // 1(单列)；2(双列)
      this.item.detail.list.map((sonItem, sonIndex) => {
        let dom = this.$refs[`item_${this.item.id}_option_${sonIndex}`];
        if (dom) {
          let domVo = window.getComputedStyle(dom[0]);
          let maxHeight =
            this.item.extension.option_layout == 1
              ? parseFloat(domVo["line-height"]) * 3
              : this.item.extension.img_layout == 2
              ? parseFloat(domVo["line-height"]) * 4
              : parseFloat(domVo["line-height"]) * 3; // 双列的时候，四行；单列的时候，3行高度
          console.log("当前界面高度", maxHeight);
          if (parseInt(domVo.height) >= parseInt(maxHeight)) {
            sonItem.isMoreHeight = "close";
          } else {
            sonItem.isMoreHeight = "";
          }
          sonItem.ellipsisShow = true;
          sonItem.maxHeight = maxHeight;
        }
      });
      this.$forceUpdate();
    },
    // 切换题目-选项 收缩 or 展开效果
    changeStatus(sonItem) {
      if (sonItem.isMoreHeight == "") return;
      sonItem.isMoreHeight = sonItem.isMoreHeight == "open" ? "close" : "open";
      this.$forceUpdate();
    },
    //处理选项状态
    getStatus(option) {
      if (this.answerType == 3) {
        if (option.is_answer == 1) {
          return "yes";
        } else if (this.item.replys == option.key && option.is_answer == 0) {
          return "no";
        } else {
          return "normal";
        }
      } else {
        return "normal";
      }
    },
    //图片预览
    preivew(...args) {
      this.$emit("preview", ...args);
    },
    // 页面初始化
    async initComp() {
      try {
        // 滚动条回到顶部
        let bodyList = document.querySelector(".exam-execute-body");
        bodyList.scrollTop = 0;
      } catch (e) {
      } finally {
        let sonList = this.item.detail.list;
        this.srcList = sonList.map((item) => item.imgUrl);
        console.log("当前题目图片列表", this.srcList);
        await this.$nextTick(() => {});
        this.setIsMoreHeight();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./scss/watch.scss";
</style>
