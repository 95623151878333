<template>
  <div>
    <vh-input
      :maxlength="100"
      autocomplete="off"
      v-model.trim="data.value"
      show-word-limit
      :placeholder="data.name"
      type="text"
      class="intro-input"
    />
    <p class="form-field-extra-rule">
      <span class="rule-tip"> 短信验证 </span>
      <vh-switch v-model="detail.verify" size="mini" />
      <span v-if="detail.verify" class="rule-tip"
        >已开启，将对手机号进行国内号段校验及验证码验证</span
      >
    </p>
  </div>
</template>

<script>
/**
 * @description 输入题
 */
export default {
  name: "q-phone",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.phone-field-edit {
}
</style>
