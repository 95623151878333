<template>
  <div class="q-option-input" @click="setEditState">
    <innerInput
      v-show="editState"
      ref="input"
      v-model="inputVal"
      :maxlength="maxlength"
      @blur="editState = false"
    />
    <p v-show="!editState" class="q-option-text" v-text="optionText" />
  </div>
</template>
<script>
import innerInput from "./innerInput.vue";
import vInputMixin from "@/vue/common/mixins/vInput";
import serial from "@/common/enum/options";
// 自定义高度input
export default {
  name: "qOptionInput",
  mixins: [vInputMixin],
  props: {
    idx: {
      type: Number,
      required: true,
      default() {
        return 0;
      },
    },
  },
  components: {
    innerInput,
  },
  data() {
    return {
      editState: false, // 当前是在编辑状态
    };
  },
  computed: {
    optionText() {
      return `${serial[this.idx]}.${this.inputVal}`;
    },
  },

  methods: {
    async setEditState() {
      this.editState = true;
      await this.$nextTick();
      const input = this.$refs.input;
      input && input.autoFocus();
      input && input.resize();
    },
  },
};
</script>
<style lang="scss">
.q-option-input {
  word-break: break-all;
  min-height: 22px;
  .vh-textarea .vh-input__count {
    bottom: 0;
  }
  :hover {
    cursor: text;
  }
}
.q-option-text {
  font-size: 14px;
  line-height: 22px;
}
.vh-input__count {
  background: transparent !important;
  // span {
  //   color: #fb2626;
  // }
}
</style>
