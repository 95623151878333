var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["create-examination"] },
    [
      _vm.qData
        ? _c("title-bar", {
            attrs: {
              totalScore: _vm.totalScore,
              totalNum: _vm.totalNum,
              singleTextQuestionsNum: _vm.singleTextQuestionsNum,
              multiTextQuestionsNum: _vm.multiTextQuestionsNum,
              singlePhotoQuestionsNum: _vm.singlePhotoQuestionsNum,
              multiPhotoQuestionsNum: _vm.multiPhotoQuestionsNum,
              client: _vm.configs.client,
            },
            model: {
              value: _vm.qData.title,
              callback: function ($$v) {
                _vm.$set(_vm.qData, "title", $$v)
              },
              expression: "qData.title",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.qData
        ? _c(
            "div",
            { class: ["create-panel", `client-${_vm.configs.client}`] },
            [
              _c("exam-pattern", {
                staticClass: "examination-pattern",
                attrs: { qData: _vm.qData },
                on: {
                  "update:qData": function ($event) {
                    _vm.qData = $event
                  },
                  "update:q-data": function ($event) {
                    _vm.qData = $event
                  },
                  onadd: _vm.addQuestion,
                },
              }),
              _vm._v(" "),
              _c("exam-questions", {
                staticClass: "examination-items-panel",
                attrs: { qData: _vm.qData, client: _vm.configs.client },
                on: {
                  "update:qData": function ($event) {
                    _vm.qData = $event
                  },
                  "update:q-data": function ($event) {
                    _vm.qData = $event
                  },
                  saveSuccess: _vm.saveSuccess,
                },
              }),
              _vm._v(" "),
              _c("exam-settings", {
                class: `client-${_vm.configs.client}`,
                attrs: { qData: _vm.qData },
                on: {
                  "update:qData": function ($event) {
                    _vm.qData = $event
                  },
                  "update:q-data": function ($event) {
                    _vm.qData = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }