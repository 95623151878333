var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vh-input", {
        staticClass: "intro-input",
        attrs: {
          maxlength: 100,
          autocomplete: "off",
          "show-word-limit": "",
          placeholder: _vm.data.name,
          type: "text",
        },
        model: {
          value: _vm.data.value,
          callback: function ($$v) {
            _vm.$set(
              _vm.data,
              "value",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "data.value",
        },
      }),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "form-field-extra-rule" },
        [
          _c("span", { staticClass: "rule-tip" }, [_vm._v(" 短信验证 ")]),
          _vm._v(" "),
          _c("vh-switch", {
            attrs: { size: "mini" },
            model: {
              value: _vm.detail.verify,
              callback: function ($$v) {
                _vm.$set(_vm.detail, "verify", $$v)
              },
              expression: "detail.verify",
            },
          }),
          _vm._v(" "),
          _vm.detail.verify
            ? _c("span", { staticClass: "rule-tip" }, [
                _vm._v("已开启，将对手机号进行国内号段校验及验证码验证"),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }