var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["exam-create-title-bar", `client-${_vm.client}`] },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "title-input-container" },
          [
            _c("q-title-input", {
              ref: "input",
              staticClass: "exam-title",
              attrs: { maxlength: 50 },
              model: {
                value: _vm.inputVal,
                callback: function ($$v) {
                  _vm.inputVal = $$v
                },
                expression: "inputVal",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("p", { staticClass: "exam-statistics" }, [
          _c("span", [
            _vm._v("\n        题数\n        "),
            _c("span", { staticClass: "exam-statistics-number" }, [
              _vm._v("\n          " + _vm._s(_vm.totalNum) + "\n        "),
            ]),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v("\n        文字单选题\n        "),
            _c("span", { staticClass: "exam-statistics-number" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.singleTextQuestionsNum) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v("文字多选题\n        "),
            _c("span", { staticClass: "exam-statistics-number" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.multiTextQuestionsNum) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v("\n        图片单选题\n        "),
            _c("span", { staticClass: "exam-statistics-number" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.singlePhotoQuestionsNum) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v("\n        图片多选题\n        "),
            _c("span", { staticClass: "exam-statistics-number" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.multiPhotoQuestionsNum) +
                  "\n        "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.totalScore,
              expression: "totalScore",
            },
          ],
          staticClass: "exam-statistics-score",
        },
        [
          _c("p", { staticClass: "tip" }, [_vm._v("总分")]),
          _vm._v(" "),
          _c("span", { staticClass: "score" }, [
            _vm._v(_vm._s(_vm.totalScore)),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }