<template>
  <div :class="['examination-questions-panel', `client-${client}`]">
    <template v-if="qData.detail.length">
      <draggable
        ref="drag"
        @update="moved"
        v-model="qData.detail"
        :scroll-sensitivity="50"
        :scroll-speed="5"
        :force-fallback="true"
        ghost-class="ghost"
        :options="{
          handle: '.sort-btn',
          animation: 200,
        }"
        @start="drag = true"
        @end="drag = false"
      >
        <div
          :class="['question-item', { 'cur-edit': idx == qData.curIndex }]"
          v-for="(question, idx) in qData.detail"
          :ref="'ques-item' + idx"
          :key="question.keyId"
          @click.stop="qData.curIndex = idx"
        >
          <div class="operation-bar">
            <span class="tag" size="small">
              {{ typenames[question.type] }}
            </span>
            <span class="btns">
              <vh-tooltip
                effect="dark"
                content="移动"
                placement="top"
                :visible-arrow="false"
              >
                <i class="vh-form-iconfont vh-form-line-rank sort-btn" />
              </vh-tooltip>
              <vh-tooltip
                effect="dark"
                content="复制"
                placement="top"
                :visible-arrow="false"
              >
                <i
                  class="vh-form-iconfont vh-form-line-copy"
                  :class="{ disabled: qCount == 100 }"
                  @click.stop="copyQuestion(idx)"
                />
              </vh-tooltip>
              <vh-tooltip
                effect="dark"
                content="删除"
                placement="top"
                :visible-arrow="false"
              >
                <i
                  class="vh-form-iconfont vh-form-line-delete"
                  @click.stop="delQuestion(idx)"
                />
              </vh-tooltip>
            </span>
          </div>
          <div class="question-content">
            <span class="serial">{{ `${idx + 1}.` }}</span>
            <component
              :data.sync="question"
              :is="'q-' + question.type"
              :multi="question.type === 'multi'"
            />
            <div class="more-option">
              <vh-button
                type="text"
                @click="addOption(question)"
                v-if="question.detail.list.length < 26"
              >
                <span class="color-blue">
                  <i class="el-icon-circle-plus-outline" /> 添加选项
                </span>
              </vh-button>
              <span class="tip-style"> 选项最多支持26个 </span>
            </div>
          </div>
        </div>
      </draggable>
    </template>
    <div v-else class="empty-tip">
      <div class="tip">
        <i class="vh-form-iconfont vh-form-line-arrow1"></i>
        <span class="empty-text"> 点击题型并设置正确答案和分数，创建题目</span>
      </div>
    </div>
    <vh-button
      type="primary"
      :disabled="qData.detail.length === 0"
      round
      class="btn"
      @click="checkSave"
      >保存</vh-button
    >
    <!-- 内层嵌套对话框 -->
    <vh-dialog
      width="400px"
      title="提示"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="innerVisible"
      top="30vh"
      append-to-body
    >
      <p class="tip-text">保存同时共享至资料管理，便于其他活动使用？</p>
      <p class="tip-check">
        <vh-checkbox v-model="isShare">共享到资料管理</vh-checkbox>
      </p>
      <div class="confirm-btns">
        <vh-button type="primary" round @click="saveForm">确 定</vh-button>
        <vh-button plain round @click="innerVisible = false">取 消</vh-button>
      </div>
    </vh-dialog>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import "@ui/components/question/edit/index.js";
import typenames from "@/common/enum/question.js";
import { throttle } from "@/common/utils.js";
export default {
  name: "ExamQuestions",
  components: {
    draggable,
  },
  data() {
    return {
      typenames,
      innerVisible: false,
      isShare: true,
    };
  },
  props: {
    qData: {},
    client: {
      type: String,
      default() {
        return "live";
      },
    },
  },
  computed: {
    qCount() {
      return this.qData.detail.length;
    },
  },
  watch: {
    "qData.curIndex": function (val) {
      if (val > -1) {
        this.$nextTick(() => {
          console.log(this.$refs["ques-item" + val]);
          // scrollIntoView()
          this.$refs["ques-item" + val][0].scrollIntoView();
        });
      }
    },
  },
  methods: {
    addOption(question) {
      question.addOption();
    },
    moved(e) {
      this.qData.setCurIndex(e.newIndex);
    },
    delQuestion(idx) {
      this.qData.deleteQuestion(idx);
    },
    copyQuestion(idx) {
      this.qData.copyQuestion(idx);
    },
    async checkSave() {
      const list = this.qData.detail;
      for (let i = 0; i < list.length; i++) {
        console.log(list[i]);
        if (!list[i].correct_answer || list[i].correct_answer.length === 0) {
          this.$message.error(`第${i + 1}题未设置正确答案`);
          return;
        }
        if (list[i].extension.has_img) {
          const error = list[i].detail.list.some((item) => {
            return !item.imgUrl;
          });
          if (error) {
            this.$message.error(`第${i + 1}题有选项未上传图片`);
            return;
          }
        }
      }
      if (this.qData.source_id && !this.qData.id) {
        this.innerVisible = true;
      } else {
        this.saveForm();
      }
    },
    saveForm: throttle(async function () {
      const { code, data, msg } = await this.qData.save(this.isShare);
      if (code == 200) {
        this.innerVisible = false;
        this.$emit("saveSuccess", data);
        this.$message.success("保存成功!");
      } else if (code == 8018002) {
        this.$message.error("保存失败");
      } else {
        this.$message.error(msg);
      }
    }, 2000),
  },
};
</script>

<style lang="scss">
.ghost {
  background: #fff;
  & > div {
    opacity: 0;
  }
}
.examination-questions-panel {
  padding: 24px 24px 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  &.client-live {
    // 覆盖上传组件样式
    .upload_content {
      width: 181px;
      height: 102px;
    }
    .photo-question .img-item {
      width: 181px;
      height: 102px;
    }
  }
  .empty-tip {
    height: 320px;
    line-height: 320px;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;

    text-align: center;
    .vh-form-line-arrow1 {
      color: #fb2626;
      font-size: 14px;
      transform: scale(1.5); // 防止基线撑开
      display: inline-block;
    }
    .empty-text {
      display: inline-block;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .question-item {
    border-radius: 4px;
    margin-bottom: 16px;
    background: #fff;
    overflow: hidden;
    &.cur-edit {
      border: 1px solid #fb2626;
    }
  }
  .question-content {
    padding: 20px 32px 20px 52px;
    position: relative;
  }
  .operation-bar {
    height: 48px;
    padding: 12px 24px;
    background: #fafafa;
    box-sizing: border-box;
    .tag {
      display: inline-block;
      background: #ffd1c9;
      border-radius: 4px;
      width: 44px;
      height: 24px;
      font-size: 14px;
      color: #fb2626;
      line-height: 24px;
      text-align: center;
    }
    .btns {
      display: inline-block;
      height: 24px;
      line-height: 24px;
      float: right;
      color: #595959;
      > i {
        font-size: 18px;
        margin-left: 18px;
        cursor: pointer;
        &.disabled {
          color: #ddd;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
      &::after {
        clear: both;
      }
    }
  }
  .serial {
    display: inline-block;
    position: absolute;
    top: 29px;
    left: 12px;
    text-align: right;
    width: 28px;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    font-size: 14px;
    color: #595959;
  }
  .btn {
    display: block;
    width: 160px;
    margin: 10px auto 0;
  }

  // deep
  .title-input-container {
    // padding: 8px 12px;
    position: relative;
    &:hover {
      background: #f5f5f5;
      border-radius: 4px;
    }
  }
  .block-option-container {
    margin-top: 8px;
    padding: 8px 12px;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    // cursor: pointer;
    width: 436px;
    &:hover {
      background: #f5f5f5;
      border-radius: 4px;
    }
  }
  .option-item {
    &:hover {
      .block-option-container {
        background: #f5f5f5;
        border-radius: 4px;
      }
      .del-btn {
        display: initial;
      }
    }
  }
  .del-btn {
    margin-top: 3px;
    margin-left: 5px;
    display: none;
    cursor: pointer;
  }
  .more-option {
    margin-top: 15px;
    font-size: 14px;
  }
}

.confirm-btns {
  text-align: right;
}
.tip-check {
  margin: 12px 0 24px;
}
</style>

<style lang="scss" scoped>
.color-blue {
  color: #1e4edc;
}
.tip-style {
  color: #8c8c8c;
}
</style>
