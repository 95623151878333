var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["animation-wrap", _vm.score == 2 ? "" : "zero"] },
    [
      _c(
        "span",
        { staticClass: "word-wrap" },
        [
          _c("WordArt", { attrs: { text: _vm.title } }),
          _vm._v(" "),
          _c("img", {
            staticClass: "star star-1",
            attrs: { src: _vm.star1, alt: "" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "star star-2",
            attrs: { src: _vm.star2, alt: "" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "star star-3",
            attrs: { src: _vm.star1, alt: "" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Ribbon"),
      _vm._v(" "),
      _c("img", { staticClass: "trophy", attrs: { src: _vm.trophyImg } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }