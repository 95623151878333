import http from "./Http.js";

const api = {
  env: "production",
  production: "//open-csd-api.vhall.com",
  release: "//pre-csd-api.vhall.com",
  test: "//test01-csd-api.vhall.com",
};
let ExamBaseUrl = api.production;
let header = {};
const setHeaders = (data) => {
  header = data;
};
// 根据业务方调用设置环境变量
const setEnv = (env) => {
  api.env = env;
  api[env] && (ExamBaseUrl = api[env]);
};
const getEnv = () => api.env;
const ajax = (options) => {
  header["Content-Type"] = "application/json";
  if (options.header) {
    header = Object.assign(header, options.header);
  }

  return http({ ...options, header });
};
const getArea = () => {
  return ajax({
    url: `https://cnstatic01.e.vhall.com/saas/common_libs/area.json`,
    method: "get",
  });
};
//图片上传
const uploadImg = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/upload-image`,
    method: "post",
    params,
    header: {
      "Content-Type": "multipart/form-data",
    },
  });
};
//创建快问快答
const createExam = (params) => {
  console.log(params);
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/create`,
    method: "post",
    params,
  });
};

//删除快问快答
const delExam = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/delete`,
    method: "post",
    params,
  });
};

//编辑快问快答
const updateExam = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/update`,
    method: "post",
    params,
  });
};

//获取快问快答列表
const getExamList = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/list`,
    method: "post",
    params,
  });
};

//获取快问快答详情(仅仅预览-题目基础，不包含答案)
const getExamDetail = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/get-info`,
    method: "post",
    params,
  });
};

//复制快问快答 - 活动维度
const copyExam = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/used-copy`,
    method: "post",
    params,
  });
};

//复制快问快答 - 用户维度【资料管理】
const copyExamUserScene = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/copy`,
    method: "post",
    params,
  });
};

//通过资料库-选择-快问快答，添加至活动
const selectExamFromUser = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/bind-to-scene`,
    method: "post",
    params,
  });
};

// 变更用户信息收集表单-是否开启状态
const editExamUserFormOpenStatus = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/user-info-form/switch-setting`,
    method: "post",
    params,
  });
};

//获取用户信息配置信息（控制台）
const getExamUserForm = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/user-info-form/get-info`,
    method: "post",
    params,
  });
};
//获取用户信息配置信息（观看端）
const getExamInitUserForm = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/user-info-form/init`,
    method: "post",
    params,
  });
};

//用户信息设置（控制台）
const editExamUserForm = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/user-info-form/setting`,
    method: "post",
    params,
  });
};

// 通过用户id查询用户信息
const getExamUserInfoById = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/user-info-form/get-user-info`,
    method: "post",
    params,
  });
};

// 变更用户信息收集表单-是否开启状态
const uploadImage = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/upload-image`,
    method: "post",
    params,
  });
};

//----------------- server层开始
// 通用 —— 快问快答-答题前置条件检查
const checkExam = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/user-info-form/check`,
    method: "post",
    params,
  });
};

// 入口1 —— 快问快答 获取推送快问快答列表
const getExamPublishList = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/get-push-list`,
    method: "post",
    params,
  });
};

//初始化用户表单信息
const getExamUserFormInit = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/user-form-info/init`,
    method: "post",
    params,
  });
};

//发送手机验证码
const sendExamPhone = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/common/verify-code/send-by-phone`,
    method: "post",
    params,
  });
};

//验证手机验证码
const checkExamPhone = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/common/verify-code/check-by-phone`,
    method: "post",
    params,
  });
};

//快问快答-保存用户信息
const submitExamUserInfo = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/user-info-form/save-user-info`,
    method: "post",
    params,
  });
};

// A、获取快问快答-信息内容（预览快问快答，不包含答案）
const getExamPreviewInfo = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/get-preview-info`,
    method: "post",
    params,
  });
};
// A、获取快问快答-信息内容（收到答题消息的聚合接口，同时提交答题用户信息）
const getPaperInfo = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/get-pushed-paper-info`,
    method: "post",
    params,
  });
};
// B、获取已答题记录，断点续答
const getExamAnswerInfo = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/get-answer-history`,
    method: "post",
    params,
  });
};

// 快问快答 -自主提交答卷（单题提交）
const submitExamAnswerInfo = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/answer`,
    method: "post",
    params,
  });
};

// 快问快答 -自主提交答卷（全部提交）
const submitExamAnswerAll = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/paper-submit`,
    method: "post",
    params,
  });
};

// 推送 - 快问快答
const sendPushExam = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/push`,
    method: "post",
    params,
  });
};

// 收卷 - 快问快答
const sendCollectExam = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/collect`,
    method: "post",
    params,
  });
};

// 公布 - 快问快答
const sendPublishExam = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/publish`,
    method: "post",
    params,
  });
};
//-------------------server层结束

//----------------- 数据server层开始
// 统计活动下所有快问快答提交人数
const getExamAnserCount = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/stats/get-answered-count`,
    method: "post",
    params,
  });
};

// 互动统计 - 快问快答列表（已推送）
const getExamPushedList = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/stats/get-pushed-list`,
    method: "post",
    params,
  });
};

// 互动统计 - 查看明细概要数据
const getExamPaperSummary = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/stats/paper-summary`,
    method: "post",
    params,
  });
};

// 互动统计 - 成绩排名列表
const getExamUserPerformances = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/stats/user-performance-list`,
    method: "post",
    params,
  });
};

// 互动统计 - 标记成绩状态
const changeExamPerformanceStatus = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/stats/mark-performance-status`,
    method: "post",
    params,
  });
};

// 互动统计 - 查看个人成绩详情
const getExamUserScope = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/stats/get-personal-report`,
    method: "post",
    params,
  });
};

// 互动统计 - 获取用户答题记录
const getUserAnswerResult = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/stats/get-user-answer`,
    method: "post",
    params,
  });
};
// 获取答题统计信息
const getExamStatistics = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/stats/question-statistics`,
    // url: `http://yapi.vhallops.com/mock/1181/v1/fqa/app/stats/question-statistics`,
    method: "post",
    params,
  });
};

// 控制台/发起端-检查快问快答分值条件
const checkFqaScore = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/check-fqa-score`,
    method: "post",
    params,
  });
};
// 观看端-批量查询问卷列表及答题状态
const batchFindByIds = (params) => {
  return ajax({
    url: `${ExamBaseUrl}/v1/fqa/app/paper/batch-find-by-ids`,
    method: "post",
    params,
  });
};

//----------------- 数据server层结束

export {
  setEnv,
  getEnv,
  getArea,
  uploadImg,
  createExam,
  delExam,
  updateExam,
  getExamList,
  getExamDetail,
  copyExam,
  copyExamUserScene,
  selectExamFromUser,
  editExamUserFormOpenStatus,
  getExamUserForm,
  editExamUserForm,
  getExamUserInfoById,
  uploadImage,
  //----------------- server层开始（观看端区域）
  checkExam,
  getExamPublishList,
  getExamUserFormInit,
  sendExamPhone,
  checkExamPhone,
  submitExamUserInfo,
  getExamPreviewInfo,
  getPaperInfo,
  getExamAnswerInfo,
  submitExamAnswerAll,
  submitExamAnswerInfo,
  sendPushExam,
  sendCollectExam,
  sendPublishExam,
  getExamInitUserForm,
  //-------------------server层结束（观看端区域）
  //----------------- 数据server层开始
  getExamAnserCount,
  getExamPushedList,
  getExamPaperSummary,
  getExamUserPerformances,
  changeExamPerformanceStatus,
  getExamUserScope,
  getUserAnswerResult,
  checkFqaScore,
  batchFindByIds,
  getExamStatistics,
  //----------------- 数据server层结束
};
export default setHeaders;
