<template>
  <div>
    <vh-input
      v-bind="$attrs"
      ref="el"
      class="inner_input"
      :placeholder="$attrs['q-placeholder']"
      v-model="inputVal"
      v-clearEmoij
      type="textarea"
      :autosize="{ minRows: 1, maxRows: 20 }"
      :maxlength="maxlength"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown.native.stop="handlePushKeyword($event)"
      show-word-limit
    />
  </div>
</template>
<script>
// 自定义高度input,无内边框
import { clearEmoij } from "@/vue/common/directive/index.js";
import vInputMixin from "@/vue/common/mixins/vInput";
export default {
  name: "iInnerInput",
  mixins: [vInputMixin],
  data() {
    return {
      oldvalue: "",
    };
  },
  watch: {
    inputVal() {
      this.inputVal = this.inputVal.replace(/\r|\n/gi, "");
    },
  },
  directives: {
    clearEmoij,
  },
  methods: {
    handleBlur() {
      if (!this.inputVal.trim()) {
        this.inputVal = this.oldvalue;
      }
      this.$emit("blur");
    },
    handleFocus(e) {
      if (/^选项[0-9]*$/.test(this.inputVal)) {
        e.target.select();
      }
      this.oldvalue = this.inputVal;
    },
    autoFocus() {
      const el = this.$refs.el;
      el && el.focus();
    },
    handlePushKeyword(event) {
      if (event.keyCode === 13) {
        event.preventDefault(); // 阻止浏览器默认换行操作
        return false;
      }
    },
    resize() {
      const el = this.$refs.el;
      el && el.resizeTextarea();
    },
  },
};
</script>
<style lang="scss">
.inner_input .vh-textarea__inner {
  display: block;
  width: 100%;
  resize: none;
  border: 0 !important; // 不管状态
  outline: 0 !important;
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  padding: 0;
  min-height: 22px;
  background: transparent;
  &::-webkit-scrollbar {
    display: none;
  }
}
.inner_input .vh-input__count .vh-input__count-inner {
  background: transparent !important;
}
.inner_input .vh-input__count {
  span {
    color: #fb2626 !important;
  }
}
</style>
