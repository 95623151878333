<template>
  <div>
    <div class="title-input-container">
      <q-title-input
        :q-placeholder="data?.extension?.placeholder"
        v-model="data.title"
      />
    </div>
    <div>
      <vh-time-select
        v-if="data.detail.fomart == 'time'"
        v-model="data.correct_answer"
        :placeholder="fomarts[data.detail.fomart]"
      >
      </vh-time-select>
      <vh-date-picker
        v-else
        v-model="data.correct_answer"
        :type="data.detail.fomart"
        class="full-width"
        :placeholder="fomarts[data.detail.fomart]"
      >
      </vh-date-picker>
    </div>
    <div class="form-field-extra-rule">
      <vh-select v-model="data.detail.fomart" size="small" class="no-border">
        <vh-option
          v-for="(value, key) in fomarts"
          :key="key"
          :label="value"
          :value="key"
        >
        </vh-option>
      </vh-select>
    </div>
  </div>
</template>

<script>
/**
 * @description 时间
 */
import qTitleInput from "@/vue/components/common/qTitleInput.vue";
export default {
  name: "q-date",
  components: { qTitleInput },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fomarts: {
        date: "日期",
        time: "时间",
        datatime: "日期时间",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.title-input-container {
  // padding: 8px 12px;
  position: relative;
  margin-bottom: 10px;
  &:hover {
    background: #f5f5f5;
    border-radius: 4px;
  }
}
.date-field-edit {
}
</style>
