export default {
  props: {
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
    },
    maxlength: {
      type: [String, Number],
      default() {
        return 100;
      },
    },
  },
  created() {
    this.inputVal = this.value;
  },
  data() {
    return {
      inputVal: "",
    };
  },
  watch: {
    inputVal(val) {
      this.$emit("input", val);
    },
  },
};
