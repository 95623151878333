var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "title-input-container" },
      [
        _c("q-title-input", {
          attrs: { "q-placeholder": _vm.data?.extension?.placeholder },
          model: {
            value: _vm.data.title,
            callback: function ($$v) {
              _vm.$set(_vm.data, "title", $$v)
            },
            expression: "data.title",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.data.detail.fomart == "time"
          ? _c("vh-time-select", {
              attrs: { placeholder: _vm.fomarts[_vm.data.detail.fomart] },
              model: {
                value: _vm.data.correct_answer,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "correct_answer", $$v)
                },
                expression: "data.correct_answer",
              },
            })
          : _c("vh-date-picker", {
              staticClass: "full-width",
              attrs: {
                type: _vm.data.detail.fomart,
                placeholder: _vm.fomarts[_vm.data.detail.fomart],
              },
              model: {
                value: _vm.data.correct_answer,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "correct_answer", $$v)
                },
                expression: "data.correct_answer",
              },
            }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-field-extra-rule" },
      [
        _c(
          "vh-select",
          {
            staticClass: "no-border",
            attrs: { size: "small" },
            model: {
              value: _vm.data.detail.fomart,
              callback: function ($$v) {
                _vm.$set(_vm.data.detail, "fomart", $$v)
              },
              expression: "data.detail.fomart",
            },
          },
          _vm._l(_vm.fomarts, function (value, key) {
            return _c("vh-option", {
              key: key,
              attrs: { label: value, value: key },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }