<template>
  <div
    :class="[
      'vmp-transcripts',
      fullMarks > 0 ? 'vmp-transcripts-extreme' : 'vmp-transcripts-other',
    ]"
  >
    <template v-if="fullMarks > 0">
      <trophy :score="fullMarks" class="trophy-wrap" />
      <p class="text">{{ text }}</p>
    </template>
    <div v-else>
      <div class="animation-panel ma">
        <img :src="annularImg" alt="" class="annular-bg" />
        <annular />
        <div class="transcripts-score absolute text-center">
          <span>
            <span class="std-text-pro d-din">{{
              result.total_score > 0
                ? result.score
                : Number(result.right_rate).toFixed(1)
            }}</span>
            <sub class="std-text-aux">{{
              result.total_score > 0 ? $t("exam.1012") : "%"
            }}</sub>
          </span>
          <br />
          <div class="total-wrap inline-block">
            <span class="std-text-aux total">
              {{
                result.total_score > 0
                  ? `${$t("exam.1011")}:${result.total_score}`
                  : $t("exam.1013")
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="statistics-panel">
        <div class="statistics-item">
          <p class="count correct">{{ result.right_num }}</p>
          <p class="tip">{{ $t("exam.1007") }}</p>
        </div>
        <div class="statistics-item">
          <p class="count error">{{ result.error_num }}</p>
          <p class="tip">{{ $t("exam.1008") }}</p>
        </div>
        <div class="statistics-item">
          <p class="count">{{ result.unanswer_num }}</p>
          <p class="tip">{{ $t("exam.1009") }}</p>
        </div>
      </div>
    </div>
    <vh-button type="primary" class="ma block button" round @click="toResult">
      {{ $t("exam.1010") }}
    </vh-button>
  </div>
</template>
<script>
import annularImg from "@ui/assets/image/score-bg.png";
import wordart from "@ui/components/common/wordart.vue";
import annular from "./annular.vue";
import trophy from "./trophy.vue";
export default {
  name: "Transcripts",
  components: {
    annular,
    trophy,
    wordart,
  },
  data() {
    return {
      score: 20,
      totalScore: 100,
      annularImg,
    };
  },
  props: {
    result: {
      default() {
        return {
          total_score: 0,
          score: 0,
          rank: 0,
          right_num: 0,
          error_num: 0,
          unanswer_num: 0,
          right_rate: 0,
        };
      },
    },
  },
  computed: {
    fullMarks() {
      if (this.result.error_num == 0 && this.result.unanswer_num == 0) {
        return 2; //满分
      } else if (this.result.right_num == 0) {
        return 1; //0分
      } else {
        return 0; //其他
      }
    },
    text() {
      if (this.result.error_num == 0 && this.result.unanswer_num == 0) {
        return this.$t("exam.1017"); //满分
      } else if (this.result.right_num == 0) {
        return this.$t("exam.1018"); //0分
      } else {
        return ""; //其他
      }
    },
  },
  methods: {
    toResult() {
      this.$emit("toResult");
    },
  },
};
</script>

<style lang="scss">
@import "~@ui/assets/font/D-DIN-Bold/stylesheet.css"; //UI要求必须是这个字体,不接受无字体样式
.d-din {
  font-family: "D-DIN";
}
.vmp-transcripts {
  text-align: center;
  .animation-panel {
    width: 354px;
    height: 177px;
    margin: auto;
    position: relative;
  }
  .annular-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .trophy-wrap {
    margin-top: 40px;
  }
  .transcripts-score {
    vertical-align: sub;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .full-marks,
  .zero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    p {
      font-size: 17px;
      line-height: 24px;
      text-align: center;
      color: #262626;
    }
  }
  .zero {
    filter: grayscale(100%);
  }

  .total {
    padding: 1px 4px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }

  .statistics-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 132px);
    margin: 30px auto 0 auto;
  }
  .statistics-item {
    text-align: center;
    .count {
      font-size: 24px;
      line-height: 24px;
      color: #262626;
      &.correct {
        color: #0fba5a;
      }
      &.error {
        color: #fb2626;
      }
    }
    .tip {
      margin-top: 8px;
      font-size: 16px;
      line-height: 16px;
      color: #595959;
    }
  }
  .text {
    margin-top: 12px;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #262626;
  }
  .button {
    margin: 24px auto;
  }
}
.exam-model-panel {
  .vmp-transcripts {
    .animation-panel {
      margin: 45px auto auto auto;
    }
  }
}
.exam-model-panel-pc {
  .vmp-transcripts {
    .animation-panel {
      margin: auto;
    }
  }
}
</style>
