<template>
  <div class="q-privacy" v-if="showPrivacyDialog">
    <vh-dialog
      class="exam-privacy"
      title="隐私协议设置"
      :visible.sync="showPrivacyDialog"
      append-to-body
      width="600px"
      :before-close="handleClose"
    >
      <div class="privacy_box">
        <div class="flex_box">
          <span class="labelRight">隐私协议</span>
          <vh-input :max-length="100" v-model="privacyContent"></vh-input>
        </div>
        <div v-for="(item, index) in details" :key="index" class="posi">
          <div class="flex_box">
            <span class="labelRight">协议名称</span>
            <vh-input
              :max-length="100"
              v-model="item.name"
              @change="(val) => (item.name = val)"
              @blur="
                (el) => {
                  changeName(el, item.name, index);
                }
              "
            />
            <span class="wid_i">
              <i
                class="iconfont-v3 saasicon_fangda"
                @click="addOption"
                v-show="details.length == 1"
              ></i>
              <i
                class="iconfont-v3 saasicon_delete"
                @click="delOption(item.name, index)"
                v-show="index == 1"
              ></i
            ></span>
          </div>
          <div v-show="item.nameErrTip" class="nameErrTip">
            {{ item.nameErrTip }}
          </div>
          <div class="flex_box">
            <span class="labelRight">跳转链接</span>
            <vh-input
              class="tarea"
              @blur="(el) => checkUrl(item, item.url, index)"
              v-model="item.url"
              :max-length="200"
              type="textarea"
              :autosize="{ minRows: 2 }"
              resize="none"
              placeholder="请输入以http://或https://开头的链接"
              @change="(val) => (item.url = val)"
            ></vh-input>
            <span class="block_"></span>
          </div>
          <div v-if="item.urlErrTip" class="urlErrTip">
            {{ item.urlErrTip }}
          </div>
        </div>
        <div class="flex_box">
          <span class="labelRight">预览效果</span>
          <div v-html="previewPrivacy" class="previewH"></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <vh-button type="primary" size="medium" round @click="savePrivacy"
          >确 定</vh-button
        >
        <vh-button size="medium" @click="handleClose" round>取 消</vh-button>
      </span>
    </vh-dialog>
  </div>
</template>

<script>
export default {
  props: ["showPrivacyDialog", "privacyDate", "isPreview"],
  data() {
    return {
      privacyContent: "我们根据《隐私声明》保护您填写的所有信息",
      details: [{ name: "《隐私声明》", url: "", urlErrTip: "" }],
      proviewVal: "",
      previewPrivacy: "",
      privacyNames: [],
      privacyChecked: false,
      errTipShow: false,
    };
  },
  watch: {
    privacyContent: {
      handler() {
        this.assemble();
      },
      // immediate: true,
    },
    privacyDate: {
      handler(val) {
        if (val) {
          this.privacyContent = val.privacyContent || this.privacyContent;
          this.details = val.details || this.details;
          this.details.forEach((i) => {
            this.$set(i, "nameErrTip", "");
            this.$set(i, "urlErrTip", "");
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.regName();
  },
  methods: {
    // 关闭弹框
    handleClose() {
      this.$emit("update:showPrivacyDialog", false);
    },
    // 保存协议内容
    savePrivacy() {
      this.details.forEach((i) => {
        if (!i.name) {
          i.nameErrTip = "请输入协议名称";
        }
      });
      if (this.details.some((i) => !!i.urlErrTip)) return;
      if (!this.details.every((i) => !!i.name) || !this.privacyContent) return;
      this.handleClose();
      this.$emit("savePrivacy", {
        details: this.details,
        privacyContent: this.privacyContent,
      });
    },
    // 添加协议
    addOption() {
      if (this.privacyContent.length > 92) {
        this.$message.warning("添加隐私协议会超出预览字数，请删减后再添加");
        return;
      }
      this.details.push({
        name: `《隐私声明2》`,
        url: "",
        urlErrTip: "",
        nameErrTip: "",
      });
      this.privacyContent = this.privacyContent.replace(
        this.details[0].name,
        `${this.details[0].name}和${this.details[1].name}`
      );
      console.log(this.privacyContent, this.details, "addOption");
    },
    // 删除协议
    delOption(val, index) {
      let str = "";
      if (index == 0) {
        str = `${val}和`;
      } else {
        str = `和${val}`;
      }
      this.privacyContent = this.privacyContent.replace(str, "");
      this.details = this.details.filter((item, i) => i != index);
      this.regName();
    },
    // 修改协议名称
    changeName(el, val, index) {
      console.log(val, "changeName");
      if (this.privacyContent.includes(val)) {
        this.details[index].name = val;
        this.details[index].nameErrTip = "";
      } else {
        this.details[index].name = "";
      }
      this.assemble();
    },
    // 正则协议名称
    regName() {
      this.assemble();
    },
    // 预览内容拼装
    assemble() {
      let str = this.privacyContent;
      let rex = `|^-^+^+^-^|`;
      let arr = JSON.parse(JSON.stringify(this.details));
      if (
        this.details.length == 2 &&
        this.details[0].name == this.details[1].name
      ) {
        arr.shift();
      }
      arr.forEach((item) => {
        // console.log(item, "item");
        if (item.name) {
          str = str.replace(item.name, rex);
        }
      });
      arr.forEach((item) => {
        if (item.name) {
          str = str.replace(
            rex,
            `<a href="${item.url || "javascript:void(0);"}" target="${
              item.url ? "_blank" : "_self"
            }">${item.name}</a>`
          );
        }
      });
      this.previewPrivacy = str;
    },
    // 检查参数
    check() {
      this.errTipShow = !this.privacyChecked;
    },
    // 检测url
    checkUrl(i, val, index) {
      console.log(i, val, index, "data,index");
      if (val) {
        let flag = /^(((https|http)?):\/\/)[0-9,a-z,A-Z]+/.test(val);
        if (flag) {
          i.urlErrTip = "";
        } else {
          i.urlErrTip = "请输入正确的网址";
        }
      }
      this.assemble();
    },
  },
};
</script>

<style lang="scss">
.exam-privacy {
  .posi {
    position: relative;
    .urlErrTip {
      position: absolute;
      bottom: -18px;
      left: 74px;
      color: #fb3a32;
      font-size: 12px;
    }
    .nameErrTip {
      position: absolute;
      bottom: 60px;
      left: 74px;
      color: #fb3a32;
      font-size: 12px;
    }
  }
  .flex_box {
    display: flex;
    margin-bottom: 20px;
    .labelRight {
      flex-shrink: 0;
      width: 75px;
      line-height: 40px;
    }
    .limit.area {
      transform: none;
      bottom: 4px;
      right: 8px;
    }
    .wid_i {
      width: 16px;
      flex-shrink: 0;
    }
    .saasicon_fangda,
    .saasicon_delete {
      line-height: 40px;
      cursor: pointer;
    }
    .block_ {
      width: 16px;
      flex-shrink: 0;
    }
    .previewH {
      line-height: 40px;
      text-align: left;
      a {
        text-decoration: none;
        color: #3562fa;
      }
    }
    .sure {
      line-height: 40px;
      margin-right: 8px;
    }
  }
  .preview_pad {
    padding: 0 75px;
    font-size: 14px;
    position: relative;
    color: #606266;
    .errtip {
      position: absolute;
      flex-shrink: 0;
      color: #fb3a32;
      font-size: 12px;
      bottom: -5px;
    }
  }
  .preview_pad_wap {
    padding: 20px;
    .previewH,
    .sure {
      line-height: 1;
    }
    .errtip {
      bottom: 0;
    }
  }
}
</style>
