var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ribbon-wrap" },
    _vm._l(_vm.ribbonList, function (item, idx) {
      return _c("img", {
        key: idx,
        class: ["ribbon", `flutter-${idx + 1}`],
        attrs: { src: item },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }