<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    id="exam-score-svg"
    width="200px"
    height="110px"
  >
    <!-- 背后的灰色线 -->
    <path
      d="M5 100
        A 75 75 , 0, 1, 1,195 100"
      fill="none"
      stroke="#EDECEE"
      stroke-linecap="round"
      stroke-width="6"
    />
    <!-- 蓝色填充线 -->
    <path
      d="M5 100
        A 75 75 , 0, 1, 1,195 100"
      fill="none"
      stroke="#33A0FF"
      stroke-linecap="round"
      stroke-width="10"
      :stroke-dasharray="length"
    />
  </svg>
</template>
<script>
const radius = 95;

export default {
  props: {
    percent: {
      type: Number,
      default() {
        return 50;
      },
    },
  },
  computed: {
    length: function () {
      let per = this.percent;
      if (this.percent > 100) {
        per = 100;
      } else if (this.percent < 0) {
        per = 0;
      }
      return (Math.PI * radius * per) / 100 + "," + Math.PI * 2 * radius;
    },
  },
};
</script>
<style scoped>
#exam-score-svg {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}
</style>
