var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["examination-settings-panel", `${_vm.configs.client}`] },
    [
      _c("div", { staticClass: "tabs-wrap" }),
      _vm._v(" "),
      _c(
        "vh-tabs",
        {
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c("vh-tab-pane", { attrs: { label: "全局设置", name: "whole" } }, [
            _c(
              "div",
              { staticClass: "tab-content tab-panel" },
              [
                _c(
                  "div",
                  { staticClass: "flex-between" },
                  [
                    _c(
                      "p",
                      [
                        _vm._v("\n            答题时长\n            "),
                        _c(
                          "vh-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              placement: "bottom-end",
                              "visible-arrow": false,
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  "\n                1.开启后，观众需在指定时间内完成答题。答题中途离开后倒计时不会停止，建议提醒观众不要长时间离开答题页面"
                                ),
                                _c("br"),
                                _vm._v(
                                  "2.答题计时，以主办方推送时间进行计时\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              staticClass:
                                "vh-form-iconfont vh-form-line-question",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("vh-switch", {
                      attrs: { size: "mini" },
                      on: { change: _vm.changeLimitTime },
                      model: {
                        value: _vm.qData.limit_time_switch,
                        callback: function ($$v) {
                          _vm.$set(_vm.qData, "limit_time_switch", $$v)
                        },
                        expression: "qData.limit_time_switch",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.qData.limit_time_switch
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "time-limit-settings" },
                          [
                            _c("span", { staticClass: "settings-label" }, [
                              _vm._v("设置时长"),
                            ]),
                            _vm._v(" "),
                            _c("vh-input-number", {
                              staticClass: "settings-input",
                              attrs: {
                                placeholder:
                                  _vm.qData.limit_time_type == 1
                                    ? "5-300"
                                    : "1-60",
                                controls: false,
                                size: "mini",
                                precision: 0,
                                min: _vm.qData.limit_time_type == 1 ? 5 : 1,
                                max: _vm.qData.limit_time_type == 1 ? 300 : 60,
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.oldvalue = _vm.qData.limit_time
                                },
                                blur: function ($event) {
                                  !_vm.qData.limit_time &&
                                    (_vm.qData.limit_time = _vm.oldvalue)
                                },
                              },
                              model: {
                                value: _vm.qData.limit_time,
                                callback: function ($$v) {
                                  _vm.$set(_vm.qData, "limit_time", $$v)
                                },
                                expression: "qData.limit_time",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "vh-select",
                              {
                                staticClass: "time_type",
                                attrs: { size: "mini" },
                                on: { change: _vm.changeTimeType },
                                model: {
                                  value: _vm.qData.limit_time_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.qData, "limit_time_type", $$v)
                                  },
                                  expression: "qData.limit_time_type",
                                },
                              },
                              [
                                _c("vh-option", {
                                  attrs: { label: "分钟", value: 0 },
                                }),
                                _vm._v(" "),
                                _c("vh-option", {
                                  attrs: { label: "秒", value: 1 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "limit-tip" }, [
                          _vm._v("需在规定时间内完成答题，超时自动收卷"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "rank-switch flex-between" },
                          [
                            _c(
                              "p",
                              [
                                _vm._v(
                                  "\n                自动推送成绩排行榜\n                "
                                ),
                                _c(
                                  "vh-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "bottom-end",
                                      "visible-arrow": false,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    开启答题限时后，倒计时结束自动推送成绩排行榜至观看端；关闭后需要人工推送\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass:
                                        "vh-form-iconfont vh-form-line-question",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("vh-switch", {
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.qData.auto_push_switch,
                                callback: function ($$v) {
                                  _vm.$set(_vm.qData, "auto_push_switch", $$v)
                                },
                                expression: "qData.auto_push_switch",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "limit-tip" }, [
                          _vm._v("答题时间到期后，自动推送排行榜给观众"),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-between display_sorce" },
                  [
                    _c(
                      "p",
                      [
                        _vm._v("\n            显示成绩及答案\n            "),
                        _c(
                          "vh-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              placement: "bottom-end",
                              "visible-arrow": false,
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  "\n                默认开启，关闭后观众完成答题不会显示成绩及每道题的答案\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              staticClass:
                                "vh-form-iconfont vh-form-line-question",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("vh-switch", {
                      attrs: {
                        size: "mini",
                        "active-value": 1,
                        "inactive-value": 0,
                      },
                      model: {
                        value: _vm.qData.display_score,
                        callback: function ($$v) {
                          _vm.$set(_vm.qData, "display_score", $$v)
                        },
                        expression: "qData.display_score",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "vh-tab-pane",
            {
              attrs: {
                label: "题目设置",
                disabled: _vm.qData.detail.length === 0,
                name: "single",
              },
            },
            [
              _vm.qData.detail[_vm.qData.curIndex]
                ? _c("div", { staticClass: "tab-panel" }, [
                    _vm.qData.detail[_vm.qData.curIndex].extension.has_img
                      ? _c(
                          "div",
                          { staticClass: "flex-start layout-settings" },
                          [
                            _c("p", { staticClass: "settings-label" }, [
                              _vm._v("样式"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "vh-radio-group",
                              {
                                attrs: { size: "mini" },
                                on: { change: _vm.changeImgLayout },
                                model: {
                                  value:
                                    _vm.qData.detail[_vm.qData.curIndex]
                                      .extension.img_layout,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.qData.detail[_vm.qData.curIndex]
                                        .extension,
                                      "img_layout",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "qData.detail[qData.curIndex].extension.img_layout",
                                },
                              },
                              [
                                _c("vh-radio-button", { attrs: { label: 1 } }, [
                                  _vm._v("上图下文"),
                                ]),
                                _vm._v(" "),
                                _c("vh-radio-button", { attrs: { label: 2 } }, [
                                  _vm._v("左图右文"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.qData.detail[_vm.qData.curIndex].extension.has_img
                      ? _c(
                          "div",
                          { staticClass: "flex-start layout-settings" },
                          [
                            _c("p", { staticClass: "settings-label" }, [
                              _vm._v("布局"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "vh-radio-group",
                              {
                                attrs: { size: "mini" },
                                model: {
                                  value:
                                    _vm.qData.detail[_vm.qData.curIndex]
                                      .extension.option_layout,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.qData.detail[_vm.qData.curIndex]
                                        .extension,
                                      "option_layout",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "qData.detail[qData.curIndex].extension.option_layout",
                                },
                              },
                              [
                                _c("vh-radio-button", { attrs: { label: 1 } }, [
                                  _vm._v(" 单列 "),
                                ]),
                                _vm._v(" "),
                                _vm.qData.detail[_vm.qData.curIndex].extension
                                  .img_layout != 2
                                  ? _c(
                                      "vh-radio-button",
                                      { attrs: { label: 2 } },
                                      [
                                        _vm._v(
                                          "\n              双列\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "score-settings" },
                      [
                        _c("span", { staticClass: "settings-label" }, [
                          _vm._v("分值"),
                        ]),
                        _vm._v(" "),
                        _c("vh-input-number", {
                          staticClass: "settings-input",
                          attrs: {
                            size: "small",
                            controls: false,
                            precision: 0,
                            min: 0,
                            max: 100,
                          },
                          model: {
                            value: _vm.qData.detail[_vm.qData.curIndex].score,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.qData.detail[_vm.qData.curIndex],
                                "score",
                                $$v
                              )
                            },
                            expression: "qData.detail[qData.curIndex].score",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(" 分 ")]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.qData.detail[_vm.qData.curIndex].type === "radio"
                      ? _c(
                          "div",
                          { staticClass: "answer-container" },
                          [
                            _c("p", { staticClass: "settings-label" }, [
                              _vm._v("正确答案 (单选)"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "vh-radio-group",
                              {
                                staticClass: "option-wrap",
                                on: { change: _vm.setCurrentAnswer },
                                model: {
                                  value:
                                    _vm.qData.detail[_vm.qData.curIndex]
                                      .correct_answer,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.qData.detail[_vm.qData.curIndex],
                                      "correct_answer",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "qData.detail[qData.curIndex].correct_answer",
                                },
                              },
                              _vm._l(
                                _vm.qData.detail[_vm.qData.curIndex].detail
                                  .list,
                                function (option) {
                                  return _c(
                                    "vh-radio",
                                    {
                                      key: option.id || option.kid,
                                      staticClass: "option-item",
                                      attrs: { label: option.key },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("overHidden")(
                                            option.key + "." + option.value,
                                            16
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.qData.detail[_vm.qData.curIndex].type === "checkbox"
                      ? _c(
                          "div",
                          { staticClass: "answer-container" },
                          [
                            _c("p", { staticClass: "settings-label" }, [
                              _vm._v("正确答案 (多选)"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "vh-checkbox-group",
                              {
                                staticClass: "option-wrap",
                                attrs: { directionVertical: "" },
                                on: { change: _vm.setCurrentAnswer },
                                model: {
                                  value:
                                    _vm.qData.detail[_vm.qData.curIndex]
                                      .correct_answer,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.qData.detail[_vm.qData.curIndex],
                                      "correct_answer",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "qData.detail[qData.curIndex].correct_answer",
                                },
                              },
                              _vm._l(
                                _vm.qData.detail[_vm.qData.curIndex].detail
                                  .list,
                                function (option) {
                                  return _c(
                                    "vh-checkbox",
                                    {
                                      key: option.id,
                                      staticClass: "option-item",
                                      attrs: { label: option.key },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("overHidden")(
                                            option.key + "." + option.value,
                                            16
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }