<template>
  <div class="user-info" v-if="qData">
    <div class="introduce-panel ma">
      <p class="title truncate">{{ title }}</p>
      <TextEllipsis
        :text="qData.guidelines"
        class="introduce"
        textClass="text-ellipsis"
        :isLimitHeight="ellipsisShow"
        :height="50"
      >
        <span
          v-if="ellipsisShow"
          @click="ellipsisShow = false"
          class="ellipsis-toggle"
          slot="more"
        >
          ...展开
        </span>
        <span
          slot="after"
          class="ellipsis-toggle link"
          v-if="!ellipsisShow"
          @click="ellipsisShow = true"
          >...收起</span
        >
      </TextEllipsis>
    </div>
    <vh-form
      label-position="top"
      ref="form"
      :rules="rules"
      :model="qData"
      class="demo-form-inline m-t-20"
    >
      <vh-form-item
        v-for="(item, idx) of qData.detail"
        :label="convertLabel(item)"
        :key="idx"
        :prop="`detail.${idx}.replys`"
        :rules="item.rules"
        class="m-b-16"
      >
        <component
          :is="convertFormField(item.extension.name)"
          class="vmp-exam-item-comp-pc"
          :item="item"
          :index="item.sortNum"
          :examServer="examServer"
        />
      </vh-form-item>
      <vh-form-item>
        <vh-button round type="primary" class="full-width" @click="onSubmit"
          >提交</vh-button
        >
      </vh-form-item>
    </vh-form>
  </div>
</template>
<script>
import TextEllipsis from "@ui/components/common/textEllipsis.vue";
import textField from "@ui/components/question/watch/Text";
import areaField from "@ui/components/question/watch/Area";
import phoneField from "@ui/components/question/watch/Phone";
import dateField from "@ui/components/question/watch/Date";
import radioField from "@ui/components/question/watch/Radio";
export default {
  name: "userInfo",
  components: {
    TextEllipsis,
    textField,
    areaField,
    phoneField,
    dateField,
    radioField,
  },
  props: {
    examServer: {},
    configs: {
      default: {
        role: 1,
        source_id: undefined,
        source_type: undefined,
      },
    },
  },
  data() {
    return {
      ellipsisShow: true,
      title: "Apple产品功能知识Apple产品功能知识Apple产品功能知识",
      // introduce: 'Apple产品功能知识Apple产品功能知识Apple产品功能知识AppleApple产品功能知识Apple产品功能知识Apple产品功能知识Apple产品功能知识Apple产品功能知识Apple产品功能知识产品功能知识Apple产品功能知识Apple产品功能知识',
      introduce: "Apple产品功能知识Apple产品功能识",
      privacyHtml:
        '<div class="previewH">提交并同意<a href="https://e.vhall.com/v3/privacyPolicy" target="_blank">《隐私政策》</a>及<a href="https://e.vhall.com/v3/privacyUPo" target="_blank">《用户保护协议》</a></div>',
      code: "",
      rules: {},
      qData: "",
    };
  },
  created() {
    this.examServer.getQA().then((res) => {
      console.log(res);
      this.qData = res;
      this.initRules();
    });
  },
  methods: {
    initRules() {
      this.qData.detail.forEach((q) => {
        q.rules = [];
        if (q.required) {
          q.rules.push({
            required: true,
            message: "此项为必填项",
            trigger: ["blur", "change"],
          });
        }
        //加入业务线姓名
        if (q.extension.name == 1) {
          q.replys = this.configs.userName;
        }
        //加入业务线手机号
        if (q.extension.name == 3) {
          q.replys = this.configs.mobile;
        }
      });
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        console.log("用户信息", this.qData);
        if (valid) {
          this.qData.submit().then((res) => {
            if (res.code == 200) {
              this.$emit("submitUserInfo");
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    convertFormField(type) {
      let fieldType = "textField";
      switch (type) {
        case 4:
          fieldType = "phoneField";
          break;
        case 1:
        case 5:
        case 6:
        case 7:
        case 9:
        case 10:
        case 11:
        case 12:
          fieldType = "textField";
          break;
        case 8:
          fieldType = "areaField";
          break;
        case 3:
          fieldType = "dateField";
          break;
        case 2:
          fieldType = "radioField";
      }
      return fieldType;
    },
    convertLabel(item) {
      const type = item.type;
      let label = "";
      const needLabelFieldTypes = ["area"];
      if (needLabelFieldTypes.includes(type)) {
        label = item.name;
      }
      return label;
    },
  },
};
</script>
<style lang="scss">
.user-info {
  padding: 10px;
  .introduce-panel {
    width: 270px;
    text-align: center;
    .title {
      font-size: 14px;
      line-height: 22px;
      color: #262626;
    }

    .introduce {
      margin-top: 4px;
      font-size: 12px;
      line-height: 18px;
      color: #595959;
    }
    .ellipsis-toggle {
      font-size: 12px;
      color: #0a7ff5;
      cursor: pointer;
    }
  }
}

.text-ellipsis {
  font-size: 12px;
  line-height: 18px;
  color: #595959;
}
</style>
