var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "date-field" },
    [
      _c("vh-date-picker", {
        staticClass: "full-width",
        attrs: {
          type: "date",
          "value-format": "yyyy-MM-dd",
          placeholder: "生日",
        },
        model: {
          value: _vm.item.replys,
          callback: function ($$v) {
            _vm.$set(_vm.item, "replys", $$v)
          },
          expression: "item.replys",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }