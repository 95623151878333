var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "user-info-create-pattern" },
    _vm._l(_vm.patternTypes, function (item, index) {
      return _c(
        "li",
        {
          key: index,
          class: [
            "pattern-item",
            _vm.activedList.includes(item.options.info.extension.name)
              ? "actived"
              : "",
          ],
          on: {
            click: function ($event) {
              return _vm.addFiled(item)
            },
          },
        },
        [
          _c("i", { class: ["vh-form-iconfont  icon ", `${item.icon}`] }),
          _vm._v(" "),
          _c("span", { staticClass: "pattern-label" }, [
            _vm._v(_vm._s(item.name)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }