<template>
  <div :class="['create-examination']">
    <!-- 标题 title -->
    <title-bar
      v-if="qData"
      v-model="qData.title"
      :totalScore="totalScore"
      :totalNum="totalNum"
      :singleTextQuestionsNum="singleTextQuestionsNum"
      :multiTextQuestionsNum="multiTextQuestionsNum"
      :singlePhotoQuestionsNum="singlePhotoQuestionsNum"
      :multiPhotoQuestionsNum="multiPhotoQuestionsNum"
      :client="configs.client"
    />
    <div :class="['create-panel', `client-${configs.client}`]" v-if="qData">
      <exam-pattern
        class="examination-pattern"
        :qData.sync="qData"
        @onadd="addQuestion"
      />
      <exam-questions
        class="examination-items-panel"
        :qData.sync="qData"
        @saveSuccess="saveSuccess"
        :client="configs.client"
      />
      <exam-settings :class="`client-${configs.client}`" :qData.sync="qData" />
    </div>
  </div>
</template>
<script>
import TitleBar from "./titleBar.vue";
import ExamPattern from "./pattern.vue";
import ExamSettings from "./settings.vue";
import ExamQuestions from "./main.vue";
export default {
  name: "CreateOrEditExam",
  components: {
    TitleBar,
    ExamPattern,
    ExamSettings,
    ExamQuestions,
  },
  props: {
    examServer: {},
    examId: {
      default: "",
    },
    configs: {
      default: {
        role: 1,
        source_id: undefined,
        source_type: undefined,
        client: "live",
      },
    },
  },
  data() {
    return {
      qData: "",
    };
  },
  computed: {
    //总条数
    totalNum() {
      return this.qData?.detail?.length || 0;
    },
    //总分
    totalScore() {
      return this.qData?.detail?.length > 0
        ? this.qData?.detail.reduce((accumulator, cur) => {
            return accumulator + Number(cur.score);
          }, 0)
        : 0;
    },
    //文字单选
    singleTextQuestionsNum() {
      return this.qData?.detail
        ? this.qData?.detail.filter((item) => {
            return item.type === "radio" && !item.extension.has_img;
          }).length
        : 0;
    },
    //文字多选
    multiTextQuestionsNum() {
      return this.qData?.detail
        ? this.qData?.detail.filter((item) => {
            return item.type === "checkbox" && !item.extension.has_img;
          }).length
        : 0;
    },
    //图片单选
    singlePhotoQuestionsNum() {
      return this.qData?.detail
        ? this.qData?.detail.filter((item) => {
            return item.type === "radio" && item.extension.has_img;
          }).length
        : 0;
    },
    //图片多选
    multiPhotoQuestionsNum() {
      return this.qData?.detail
        ? this.qData?.detail.filter((item) => {
            return item.type === "checkbox" && item.extension.has_img;
          }).length
        : 0;
    },
  },
  created() {
    this.examServer
      .getExam({
        id: this.examId,
        role: this.configs.role,
        source_id: this.configs.source_id,
        source_type: this.configs.source_type,
      })
      .then((res) => {
        console.log(res);
        this.qData = res;
        this.questionnaire = res;
      });
  },
  methods: {
    //保存成功
    saveSuccess(data) {
      this.examServer.$emit(this.examServer.events["SUBMITEDIT"], data);
    },
    addQuestion({ type, info }) {
      this.questionnaire.addQuestion(type, info);
    },
  },
};
</script>
<style lang="scss">
.create-examination {
  height: 100%;
  &.live {
    height: 467px; // 弹窗适配高度
  }
  .create-panel {
    display: flex;
    height: calc(100% - 72px);
    background: #f0f0f0;
    border-radius: 4px;
    &.client-live {
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #e6e6e6;
    }
  }
  .examination-pattern {
    box-sizing: border-box;
    height: 395px;
  }
  // 中间操作区css
  .examination-items-panel {
    box-sizing: border-box;
    height: 395px;
    flex: 1;
  }

  // 右侧
  .examination-settings-panel {
    width: 268px;
    height: calc(100% - 72px);
    background: #fff;
    &.client-console {
      width: 284px;
    }
    &.client-live {
      height: 395px;
    }
  }

  $correct: #0fba5a; //覆盖样式单选框
  .vh-radio__input.is-checked .vh-radio__inner {
    border-color: $correct !important;
  }
  .vh-radio__inner::after {
    background-color: $correct !important;
  }
  .vh-radio:hover .vh-radio__inner {
    border-color: $correct !important;
  }
  .vh-checkbox:hover .vh-checkbox__inner {
    border-color: $correct !important;
  }
  .vh-checkbox__input.is-checked .vh-checkbox__inner {
    border-color: $correct !important;
    background-color: $correct !important;
  }
}
</style>
