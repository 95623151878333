var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "exam-execute-footer" },
    [
      _c(
        "vh-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.page > 1,
              expression: "page > 1",
            },
          ],
          staticClass: "gray--button",
          attrs: { type: "info", size: "medium", plain: "", round: "" },
          on: {
            click: function ($event) {
              return _vm.setCurPage(--_vm.page)
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.configs.pageSize == 1 ? _vm.$t("exam.1004") : "上一页"
              ) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "vh-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.page < _vm.totalPage,
              expression: "page < totalPage",
            },
          ],
          attrs: {
            type: "danger",
            size: "medium",
            round: "",
            disabled: _vm.isDisabled,
          },
          on: {
            click: function ($event) {
              return _vm.setCurPage(++_vm.page)
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.configs.pageSize == 1 ? _vm.$t("exam.1005") : "下一页"
              ) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "vh-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.curPage == _vm.totalPage && _vm.answerType == 1,
              expression: "curPage == totalPage && answerType == 1",
            },
          ],
          attrs: {
            type: "danger",
            size: "medium",
            round: "",
            disabled: _vm.isDisabled,
            id: "exam_sdk_sub_btn",
          },
          on: { click: _vm.submit },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("exam.1006")) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }