var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "q-title-input",
      class: _vm.editState ? "edit" : "",
      on: { click: _vm.setEditState },
    },
    [
      _c("innerInput", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.editState,
            expression: "editState",
          },
        ],
        ref: "input",
        attrs: { maxlength: _vm.maxlength },
        on: {
          blur: function ($event) {
            _vm.editState = false
          },
        },
        model: {
          value: _vm.inputVal,
          callback: function ($$v) {
            _vm.inputVal = $$v
          },
          expression: "inputVal",
        },
      }),
      _vm._v(" "),
      _c("p", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.editState,
            expression: "!editState",
          },
        ],
        staticClass: "q-title-text",
        domProps: { textContent: _vm._s(_vm.inputVal) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }