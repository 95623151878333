import { render, staticRenderFns } from "./main.vue?vue&type=template&id=1b89b8b7&scoped=true&"
import script from "./main.vue?vue&type=script&lang=js&"
export * from "./main.vue?vue&type=script&lang=js&"
import style0 from "./main.vue?vue&type=style&index=0&id=1b89b8b7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.0_m252fu66fivcrdajfhoxhvxrne/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b89b8b7",
  null
  
)

export default component.exports