<template>
  <div
    :class="[
      'exam-model-panel',
      fullMarks > 0 && answerType == 2
        ? 'exam-computed-transcript'
        : 'exam-computed-other',
    ]"
    v-loading="loading"
  >
    <!-- <userInfo
      :configs="configs"
      :examServer="examServer"
      v-if="answerType == 0"
      @submitUserInfo="submitUserInfo"
    ></userInfo> -->
    <div class="exam-execute-header" v-if="answerType == 1 || answerType == 3">
      <!-- 标题栏 -->
      <div class="exam-execute-title">
        <template>
          <div class="exam-execute--time">
            <img
              src="https://s3.e.vhall.com/common-static/vhall-form/images/icon_exam_time.png"
              class="exam_time__icon"
              alt=""
            />
            <div class="exam_time__text" v-if="answerType != 3">
              <!-- 答题（答题倒计时） -->
              <template
                v-if="
                  answerFormat &&
                  (answerFormat.hour >= 0 || answerFormat.minute >= 0)
                "
              >
                <ul v-if="questionnaire && questionnaire.limit_time_switch > 0">
                  <li class="exam-css-timer">
                    {{
                      answerFormat.hour > 0
                        ? answerFormat.hour > 9
                          ? answerFormat.hour
                          : `0${answerFormat.hour}`
                        : "00"
                    }}:{{
                      answerFormat.minute > 0
                        ? answerFormat.minute > 9
                          ? answerFormat.minute
                          : `0${answerFormat.minute}`
                        : "00"
                    }}
                  </li>
                </ul>
                <span v-else class="exam-css-timer">{{ $t("exam.1001") }}</span>
              </template>
              <!-- <span v-else class="exam-css-timer">{{ $t("exam.1001") }}</span> -->
            </div>
            <div class="exam_time__text" v-else>
              <!-- 查看结果（答题时间） -->
              <ul
                v-if="
                  answerFormat &&
                  (answerFormat.hour > 0 || answerFormat.minute > 0)
                "
              >
                <li class="exam-css-timer">
                  {{
                    answerFormat.hour > 0
                      ? answerFormat.hour > 9
                        ? answerFormat.hour
                        : `0${answerFormat.hour}`
                      : "00"
                  }}:{{
                    answerFormat.minute > 0
                      ? answerFormat.minute > 9
                        ? answerFormat.minute
                        : `0${answerFormat.minute}`
                      : "00"
                  }}
                </li>
              </ul>
            </div>
          </div>
        </template>
        <div class="exam-execute--status" v-if="questionnaire && totalPage">
          <span>{{ curPage }}/{{ totalPage }}</span>
        </div>
        <div class="exam-execute--close">&nbsp;</div>
      </div>
      <!-- 进度条 -->
      <vh-progress
        v-if="configs.pageSize > 0 && totalPage > 1"
        :percentage="percentage"
        :show-text="false"
        class="exam-zdy-progress"
        :stroke-width="3"
      />
      <!-- 分割条 -->
      <div class="exam-padding-line"></div>
    </div>
    <!-- 内容区域 -->
    <exam-info
      class="exam-execute-body"
      ref="examInfoDom"
      :examServer="examServer"
      :configs="configs"
      :questionList="questionList"
      :answerType="answerType"
      :curPage="curPage"
      :totalPage="totalPage"
      v-if="questionnaire && (answerType == 1 || answerType == 3)"
    ></exam-info>
    <!-- 底部 -->
    <!-- <div class="exam-execute-footer" v-if="answerType == 1 || answerType == 3">
      <vh-button
        type="info"
        size="medium"
        plain
        round
        @click="curPage--"
        v-show="curPage > 1"
        class="gray--button"
      >
        {{ configs.pageSize == 1 ? $t("exam.1004") : "上一页" }}
      </vh-button>
      <vh-button
        type="danger"
        size="medium"
        round
        :disabled="isDisabled"
        @click="curPage++"
        v-show="curPage < totalPage"
      >
        {{ configs.pageSize == 1 ? $t("exam.1005") : "下一页" }}
      </vh-button>
      <vh-button
        type="danger"
        size="medium"
        round
        :disabled="isDisabled"
        @click="submit"
        v-show="curPage == totalPage && answerType == 1"
      >
        {{ $t("exam.1006") }}
      </vh-button>
    </div> -->
    <!-- 查看答案 -->
    <exam-result
      :result="result"
      ref="examResultDom"
      @toResult="showExamResultInfo"
      v-if="answerType == 2 && result"
    ></exam-result>
  </div>
</template>
<script>
import ExamInfo from "./components/exam-info.vue";
import ExamResult from "../WatchPC/components/exam-transcript/index.vue";
// import userInfo from "../../Questionnaire/watch/index.vue";
import { getNowDate } from "@/common/utils.js";
export default {
  name: "VmpExamCore",
  components: {
    ExamInfo,
    ExamResult,
    // userInfo,
  },
  props: {
    examServer: {},
    examId: "",
    configs: {
      default: () => {
        return {
          pageSize: 1,
          answerType: 1, // 当前步骤（1--填写步骤；2--得知答案步骤；3--查看填写结果）
          account_id: "", //cd端id   发卷人查看指定人答题结果是必传
          account_type: "", //2.c端用户id 3.c端游客id 4.c端参会id
          userName: "",
          headImg: "",
          mobile: "",
          preview: false, //是否需要外测预览
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      answerTimer: null, // 倒计时-定时器
      answerTimeNum: 0, // 倒计时的时间（单位：时间转换为秒）
      answerFormat: {
        day: 0, // 天
        hour: 0, // 时
        minute: 0, // 分
        second: 0, // 秒
        hourCycle: 0, // 毫秒
      },
      timeLoaded: false, // 时间未查询出结果
      //答卷数据
      questionnaire: "",
      //题目列表
      questionList: [],
      //当前题目页码
      curPage: 1,
      //总页面
      totalPage: 1,
      // 是否不可点击下一题
      isDisabled: true,
      //答题成绩
      result: "",
      answerType: "",
    };
  },
  computed: {
    fullMarks() {
      if (this.result.error_num == 0 && this.result.unanswer_num == 0) {
        return 2; //满分
      } else if (this.result.right_num == 0) {
        return 1; //0分
      } else {
        return 0; //其他
      }
    },
    percentage() {
      if (this.totalPage > 0) {
        return (this.curPage / this.totalPage) * 100;
      } else {
        return 0;
      }
    },
  },
  provide() {
    return {
      setCurPage: this.setCurPage,
      submit: this.submit,
    };
  },
  methods: {
    setCurPage(page) {
      // 设置当前页
      this.curPage = page;
    },
    // 提交记录后，展示下一个面板
    async submit() {
      // 提交的时候，停止答题倒计时
      if (this.questionnaire.role != 1) {
        this.loading = true;
        await this.questionnaire.submitAnswer();
        //TODO等待后端数据入库再查询
        await this.getAnswerResult();
        this.loading = false;
        this.examServer.$emit(
          this.examServer.events["SUBMITANSWER"],
          this.questionnaire
        );
      } else {
        this.result = this.questionnaire.getLocalResult();
      }
      if (this.questionnaire.display_score == 1) {
        this.answerType = 2;
        this.curPage = 1;
      }
      // 关闭计时器
      clearInterval(this.answerTimer);
      this.answerTimer = null;
      //获取当前试卷成绩
    },
    // 关闭弹窗
    async close() {
      this.examServer.$emit("CLOSEPANNEL");
    },
    // 查看答案
    async showExamResultInfo() {
      this.answerType = 3;
      // 关闭计时器
      this.resetTimers();
      this.$forceUpdate();
    },
    // 倒计时 -- 初始化
    resetTimers() {
      clearInterval(this.answerTimer);
      this.answerTimer = null;
      this.answerFormat = {
        day: 0, // 天
        hour: 0, // 时
        minute: 0, // 分
        second: 0, // 秒
        hourCycle: 0, // 毫秒
      };
      if (this.answerType == 3) {
        // 展示作答时间
        let maxTime =
          Number(this.questionnaire?.limit_time || 0) *
          (this.questionnaire?.limit_time_type == 1 ? 1 : 60);
        console.log(
          "是否进入了",
          maxTime,
          this.answerTimeNum,
          this.result?.user_time
        );
        this.answerTimeNum = Number(
          this.result?.use_time || maxTime - this.answerTimeNum
        );
        this.computeAnswerTime();
      } else {
        // 展示倒计时
        let pushtime = this.questionnaire?.push_timestamp;
        console.log(
          "是否进入了22222",
          pushtime,
          this.answerTimeNum,
          this.result?.user_time
        );
        if (!pushtime) {
          // 控制台 - 预览
          this.answerTimeNum =
            Number(this.questionnaire?.limit_time || 0) *
            (this.questionnaire?.limit_time_type == 1 ? 1 : 60);
          this.computeAnswerTime();
        } else {
          this.answerTimeNum =
            Number(this.questionnaire?.limit_time || 0) *
              (this.questionnaire?.limit_time_type == 1 ? 1 : 60) -
            parseInt(
              (getNowDate(8) - this.questionnaire?.push_timestamp) / 1000
            );
        }
      }
    },
    // 倒计时 -- 换算
    computeAnswerTime() {
      let timer = this.answerTimeNum;
      let hour = Math.floor(timer / 60);
      let minute = timer - hour * 60;
      this.answerFormat.hour = hour;
      this.answerFormat.minute = minute;
      // console.log(
      //   `zzzzz: ${this.answerFormat.hour}-${this.answerFormat.minute}`
      // );
    },
    // 倒计时 -- 执行
    executeInterval() {
      // 第一步： 拿基础数据
      this.resetTimers();
      // 第二步： setInterval启动计时器
      this.answerTimer = setInterval(() => {
        this.answerTimeNum--;
        console.log("当前数据", this.answerTimeNum);
        if (this.answerTimeNum > 0) {
          this.computeAnswerTime();
        }
        if (this.answerTimeNum === 0) {
          // TODO 倒计时一停止，直接关闭弹窗
          clearInterval(this.answerTimer);
        }
      }, 1000);
    },
    //获取当前页码问题列表
    getCurList() {
      this.questionList = this.configs.pageSize
        ? this.questionnaire.detail.slice(
            this.configs.pageSize * (this.curPage - 1),
            this.configs.pageSize * this.curPage
          )
        : this.questionnaire.detail;
      this.$forceUpdate();
    },
    //加载问卷数据
    async loadExam(data) {
      // TODO 根据参数区分 —— 当前是答题 or 是查看答案。
      await this.examServer
        .getExam({
          id: this.examId,
          user_info: data,
        })
        .then((res) => {
          //设置当前答题索引
          res.anserIndex = 0;
          this.questionnaire = res;
          console.log("查看当前结构", this.questionnaire);
          //设置总页数
          this.totalPage = Math.ceil(
            this.questionnaire.detail.length / this.configs.pageSize
          );
          this.getCurList();
          if (this.answerType == 3) {
            this.resetTimers();
          } else {
            this.executeInterval();
          }
        });
      if (this.answerType == 1) {
        this.questionnaire.role == 2 && this.continueAnswer();
      } else {
        await this.getAnswerResult();
      }
    },
    //续答
    async continueAnswer() {
      const res = await this.questionnaire.getExamAnswerInfo();
      if (res.data.length > 0) {
        res.data.length == this.questionnaire.detail.length
          ? (this.curPage = res.data.length)
          : (this.curPage = res.data.length + 1);
        this.questionnaire.detail.forEach((qitem) => {
          const curitem = res.data.find((ritem) => {
            return qitem.id == ritem.question_id;
          });

          if (!curitem) {
            return;
          }
          if (qitem.type == "checkbox") {
            qitem.replys = curitem.answer.split(",");
          } else {
            qitem.replys = curitem.answer;
          }
        });
      }
    },
    //获取答题结果
    async getAnswerResult() {
      const { data } = await this.questionnaire.getExamUserScope({
        account_id: this.configs.account_id,
        account_type: this.configs.account_type,
      });
      this.result = data;
      console.log(this.questionnaire);
    },
    async submitUserInfo() {
      this.answerType = 1;
      await this.loadExam();
    },
  },
  watch: {
    curPage: function (page) {
      this.getCurList();
    },
  },
  beforeDestroy() {
    this.resetTimers();
  },
  created() {
    this.answerType = this.configs.answerType;
    if (
      this.examServer.role != 2 ||
      this.answerType == 2 ||
      this.answerType == 3
    ) {
      this.loadExam();
      return;
    }
    if (this.answerType == 1) {
      this.loadExam({
        user_name: this.configs.userName,
        head_img: this.configs.headImg,
        mobile: this.configs.mobile,
        email: this.configs.email,
        paper_id: this.examId,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.exam-model-panel {
  width: 100%;
  height: 100%;
  position: relative;
  .exam-execute-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 8px 0;
    min-height: 26px;
  }
  .exam-execute--time {
    text-align: center;
    .exam_time__icon {
      width: 16px;
      height: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .exam_time__text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #000000;
      margin-top: 1px;
      ul {
        li {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .exam-css-timer {
        // font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: #000000;
      }
    }
  }
  .exam-execute--status {
    margin-left: auto;
    span {
      // font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }
  .exam-execute--close {
    margin-left: 16px;
    width: 14px;
    height: 14px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .exam-padding-line {
    width: 100%;
    height: 1px;
    margin-top: 9px;
    background: unset;
  }
}
.exam-execute-header {
  width: 100%;
  height: 60px;
  // background: url("https://s3.e.vhall.com/common-static/vhall-form/images/bg_header_default.png")
  //   no-repeat;
  // background-size: cover;
  // background-color: #ffffff;
  // background-position: top;
  .exam-execute-title {
    margin: 0 16px;
  }
  .exam-zdy-progress {
    margin: 0 16px 10px 16px;
  }
}
.exam-execute-body {
  height: 316px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 16px;
  margin-right: 2px;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #8c8c8c;
  }
}

/* 会被重置的样式 */
::v-deep .exam-zdy-progress {
  .vh-progress-bar__outer {
    background: rgba(0, 0, 0, 0.06);
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    .vh-progress-bar__inner {
      background: rgba(251, 38, 38, 1);
      background-color: rgba(251, 38, 38, 1);
      border-radius: 2px;
    }
  }
}
</style>
