import questionTypes from "../../questionTypes";
import { deepCopy } from "@/common/utils.js";
class BaseForm {
  constructor(id) {
    this.id = id;
    this.title = "标题名称";
    this.limit_time_switch = 0; //限时开关0:关，1:开
    this.limit_time = 0; //限时时长
    this.limit_time_type = 0; //0分钟，1秒
    this.detail = {}; //扩展字段
    this.imgUrl = ""; //问卷头图
    this.description = ""; //问卷描述
    this.detail = []; //问题列表
    this.pageSize = 0; //每页题目数 0为不限制
    this.curIndex = -1; //当前问题索引
  }
  //初始化问卷数据
  _init(data) {
    Object.assign(this, data);
  }
  //创建题目
  addQuestion(type, info) {
    console.log("题目类型", type);
    const question = new questionTypes[type](deepCopy(info));
    this.detail.splice(++this.curIndex, 0, question);
    question.sortNum = this.curIndex + 1;
    console.log("---->", question);
    return question;
  }
  //删除题目
  deleteQuestion(index) {
    if (this.curIndex == index) {
      if (index > 0) {
        this.curIndex--;
      } else if (index == 0) {
        if (this.detail.length > 1) {
          this.curIndex = 0;
        } else {
          this.curIndex = -1;
        }
      }
    } else if (this.curIndex > index) {
      this.curIndex--;
    }
    this.detail.splice(index, 1);
  }
  //复制题目
  copyQuestion(index) {
    const data = deepCopy(this.detail[index]);
    data.id = "";
    console.log(data);
    const question = new questionTypes[data.type](data);
    this.detail.splice(index + 1, 0, question);
    this.curIndex = index + 1;
  }
  //设置当前编辑题目索引
  setCurIndex(index) {
    this.curIndex = index;
  }
}
export default BaseForm;
