<template>
  <div class="ribbon-wrap">
    <img
      :class="['ribbon', `flutter-${idx + 1}`]"
      v-for="(item, idx) of ribbonList"
      :key="idx"
      :src="item"
    />
  </div>
</template>

<script>
import ribbon1 from "@ui/assets/image/ribbon/ribbon-6.png";
import ribbon2 from "@ui/assets/image/ribbon/ribbon-2.png";
import ribbon3 from "@ui/assets/image/ribbon/ribbon-3.png";
import ribbon4 from "@ui/assets/image/ribbon/ribbon-4.png";
import ribbon5 from "@ui/assets/image/ribbon/ribbon-5.png";

export default {
  name: "Ribbon",
  data() {
    return {
      ribbonList: [ribbon1, ribbon2, ribbon3, ribbon4, ribbon5],
    };
  },
};
</script>

<style>
.ribbon-wrap {
  position: absolute;
  width: 100%;
  height: 100px;
  pointer-events: none;
  bottom: 0;
  left: 0;
}

.ribbon {
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 8px;
  object-fit: contain;
  animation-iteration-count: 2;
  animation-timing-function: linear;
  animation-name: flutter;
  animation-fill-mode: forwards;
}

.flutter-1 {
  left: 28px;
  animation-duration: 2400ms;
}

.flutter-2 {
  left: 133px;
  animation-duration: 1800ms;
}

.flutter-3 {
  left: 24px;
  animation-duration: 1500ms;
}

.flutter-4 {
  left: 0;
  animation-duration: 2000ms;
}

.flutter-5 {
  left: 128px;
  animation-duration: 2200ms;
}

@keyframes flutter {
  0% {
    opacity: 1;
    top: 0;
  }
  25% {
    transform: rotateX(90deg) rotateY(20deg);
  }
  50% {
    transform: rotateX(180deg) rotateY(110deg);
  }
  75% {
    transform: rotateX(240deg) rotateY(200deg);
  }
  100% {
    top: 100px;
    transform: rotateX(360deg) rotateY(290deg);
    visibility: hidden;
  }
}
</style>
