import promise from "es6-promise";
import "cropperjs/dist/cropper.css";
import VueI18n from "vue-i18n";
import languages from "@/lang/index.js";
import { ExamServer} from "@/sdk/src/index.js";
import ExamEdit from "@ui/views/Exam/Edit/index.vue";
import ExamPC from "@ui/views/Exam/WatchPC/main.vue";
import ExamWap from "@ui/views/Exam/WatchWap/main.vue";
import UserInfoEdit from "@ui/views/Questionnaire/Edit/index.vue";
import UserInfoWatch from "@ui/views/Questionnaire/watch/index.vue";
import "@ui/components/question/watch/index.js";

import "@ui/assets/font/iconfont.css";
import "@ui/assets/css/base.scss";
import "@ui/assets/css/uno.scss";
import "@ui/assets/css/standard.scss";

promise.polyfill();

const components = {
  examedit: ExamEdit,
  userInfoEdit: UserInfoEdit,
  UserInfoWatch: UserInfoWatch,
  exampc: ExamPC,
  examwap: ExamWap,
};

class ExamTemplateServer extends ExamServer {
  constructor({ role, source_type = 1, source_id, accountInfo, env }) {
    super({ role, source_type, source_id, accountInfo, env });
    if (ExamTemplateServer?.inst?.constructor === ExamTemplateServer) {
      return ExamTemplateServer.inst;
    }
    this.initLang();
    ExamTemplateServer.inst = this;
  }
  initLang() {
    if (!window.i18n) {
      Vue.use(VueI18n);
    }
    this.i18n = new VueI18n({
      locale: "zh-CN", // 设置地区
      messages: {
        zh: languages.zh,
        en: languages.en,
      },
      silentTranslationWarn: true,
    });
  }

  /**examId:试卷id,el:挂载点,compontenName:要挂栽的组件名称,
   * compontenName为exampc/examwap时
   * config:{
   * pageSize:"每页题目数"
   *
   *
   * }
   *
   * */
  mount({ examId, el, componentName, configs, lang = "zh" }) {
    this.i18n.locale = lang;
    const component = components[componentName];
    const ComConstructor = Vue.extend(component);
    console.log("i18n", this.i18n);
    const instance = new ComConstructor({
      i18n: this.i18n,
      propsData: { configs, examId, examServer: this },
    });
    instance && instance.$mount(el);
  }
}
window.ExamTemplateServer = ExamTemplateServer;
