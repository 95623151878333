import BaseInfo from "./BaseInfo";
import optionList from "../../common/enum/options";
import { getUUID, deepObjectMerge } from "@/common/utils.js";
export default class RadioInfo extends BaseInfo {
  constructor(options) {
    super(options);
    // 类型
    this.type = "radio";
    // 单选选项
    this.items = [];
    //是否可上传图片
    this.replys = "";
    //选项列表
    this.detail.list = [
      {
        key: optionList[0],
        value: "选项1",
        is_answer: 0,
        imgUrl: "",
        kid: getUUID(),
      },
      {
        key: optionList[1],
        value: "选项2",
        is_answer: 0,
        imgUrl: "",
        kid: getUUID(),
      },
    ];
    deepObjectMerge(this, options);
    //正确答案
    this.detail.list.forEach((item) => {
      if (item.is_answer == 1) {
        this.correct_answer = item.key;
        return;
      }
    });
  }
  //删除选项
  delOption(index) {
    this.detail.list.splice(index, 1);
    this.detail.list.forEach((item, index) => {
      item.key = optionList[index];
    });
    // 找出正确选项,可能删掉的就是正确选项
    const target = this.detail.list.find((item) => {
      return item.is_answer == 1;
    });
    if (target) {
      this.correct_answer = target.key
    } else {
      this.correct_answer = ''
    }
  }
  //增加选项
  addOption(option) {
    if (this.detail.list.length > 29) {
      return;
    }
    this.detail.list.push(
      deepObjectMerge(
        {
          key: optionList[this.detail.list.length],
          value: `选项${this.detail.list.length + 1}`,
          is_answer: 0,
          imgUrl: "",
          kid: getUUID(),
        },
        option
      )
    );
  }
  //设置正确答案
  setCurrentAnswer(answer) {
    this.correct_answer = answer;
    this.detail.list.forEach((item) => {
      if (this.correct_answer == item.key) {
        item.is_answer = 1;
      } else {
        item.is_answer = 0;
      }
    });
  }
  //获取当前题对错
  getResult() {
    return this.replys == this.correct_answer;
  }
}
