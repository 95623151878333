<template>
  <div>
    <div class="title-input-container">
      <q-title-input
        :q-placeholder="data?.extension?.placeholder"
        v-model="data.title"
      />
    </div>
    <div class="form-field-extra-rule">
      <div class="area-level" v-for="(level, index) of levels" :key="index">
        <span class="input-wrap">
          <vh-input
            :value="level.label"
            type="text"
            disabled
            class="intro-input"
          />
        </span>
        <vh-switch
          v-if="index != 0"
          v-model="level.isRemove"
          @change="changeLevel(level.isRemove, index)"
          class="switch"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @description 地域题
 */
import qTitleInput from "@/vue/components/common/qTitleInput.vue";
export default {
  name: "q-area",
  components: { qTitleInput },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      levels: [
        {
          value: "province",
          label: "省/自治区/直辖市",
          isRemove: this.data.detail.level > 0,
        },
        {
          value: "city",
          label: "市",
          isRemove: this.data.detail.level > 1,
        },
        {
          value: "county",
          label: "区/县",
          isRemove: this.data.detail.level > 2,
        },
        {
          value: "address",
          label: "请输入详细地址",
          isRemove: this.data.detail.level > 3,
        },
      ],
    };
  },
  methods: {
    changeLevel(status, index) {
      if (status) {
        this.data.detail.level = index + 1;
      } else {
        this.data.detail.level = index;
      }
      this.levels.forEach((item, i) => {
        if (i < index) {
          item.isRemove = true;
        } else if (i > index) {
          item.isRemove = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.area-field-edit {
}
</style>
