var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "photo-question" }, [
    _c(
      "div",
      { staticClass: "title-input-container" },
      [
        _c("q-title-input", {
          attrs: { "q-placeholder": _vm.data.extension.placeholder },
          model: {
            value: _vm.data.title,
            callback: function ($$v) {
              _vm.$set(_vm.data, "title", $$v)
            },
            expression: "data.title",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "options-wrap" },
      [
        _c(
          "vh-row",
          {
            attrs: { gutter: _vm.data.extension.option_layout === 1 ? 0 : 18 },
          },
          [
            _c(
              "vh-checkbox-group",
              {
                staticStyle: { width: "100%" },
                on: { change: _vm.setCurrentAnswer },
                model: {
                  value: _vm.data.correct_answer,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "correct_answer", $$v)
                  },
                  expression: "data.correct_answer",
                },
              },
              [
                _c(
                  "draggable",
                  {
                    attrs: {
                      "scroll-sensitivity": 50,
                      "scroll-speed": 5,
                      "force-fallback": true,
                      "ghost-class": "ghost",
                      options: {
                        handle: ".sort-btn",
                        animation: 200,
                      },
                    },
                    on: { end: _vm.moved },
                    model: {
                      value: _vm.data.detail.list,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.detail, "list", $$v)
                      },
                      expression: "data.detail.list",
                    },
                  },
                  _vm._l(_vm.data.detail.list, function (item, index) {
                    return _c(
                      "vh-col",
                      {
                        key: item.id || item.kid,
                        class:
                          _vm.data.extension.option_layout === 1
                            ? "vh-col-item-24"
                            : index % 2 === 0
                            ? "vh-col-item-12 vh-col-item-12--1"
                            : "vh-col-item-12 vh-col-item-12--2",
                        staticStyle: { position: "relative" },
                        attrs: {
                          span:
                            _vm.data.extension.option_layout === 1 ? 24 : 12,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "option-item",
                              _vm.data.extension.img_layout === 1
                                ? "column"
                                : "row",
                            ],
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "sort-btn",
                                class: _vm.data.extension.has_img
                                  ? "has-image"
                                  : "",
                              },
                              [
                                _c("img", {
                                  staticStyle: { cursor: "move" },
                                  attrs: {
                                    width: "8",
                                    src: "https://cnstatic01.e.vhall.com/upload/interacts/screen-imgs/202404/4e/00/4e0048314a03b356b433a19a7e41584a9065.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.data.detail.list.length > 2,
                                  expression: "data.detail.list.length > 2",
                                },
                              ],
                              staticClass: "del-btn vh-icon-remove-outline",
                              class: { has_img: _vm.data.extension.has_img },
                              on: {
                                click: function ($event) {
                                  return _vm.delOption(index)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.data.extension.has_img
                              ? _c(
                                  "upload",
                                  {
                                    staticClass: "img-upload img-item",
                                    attrs: { imgUrl: item.imgUrl },
                                    on: {
                                      uploadSuccess: (url, mode) => {
                                        item.imgUrl = url
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      { attrs: { slot: "tip" }, slot: "tip" },
                                      [
                                        _vm._v(
                                          "\n                    建议尺寸：*px，小于4M"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                    支持jpg、gif、png、bmp\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "option-item-text" },
                              [
                                _c(
                                  "vh-checkbox",
                                  {
                                    staticClass: "selector",
                                    attrs: { label: item.key },
                                  },
                                  [_c("i")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-wrap" },
                                  [
                                    _c("q-option-input", {
                                      attrs: {
                                        placeholder: "请输入内容",
                                        maxlength: "100",
                                        idx: index,
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }