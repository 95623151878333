const formData = [
  {
    name: "手机号",
    // type: 'birthday',
    type: "phone",
    value: "手机号",
    required: false,
    verify: false,
  },
  {
    name: "职务",
    type: "duty",
    value: "职务",
    required: false,
  },
  {
    name: "教育水平",
    type: "educational",
    value: "教育水平",
    required: false,
  },
  {
    name: "行业",
    type: "industry",
    value: "行业",
    required: false,
  },
  {
    name: "工号",
    type: "empno",
    value: "工号",
    required: false,
  },
  {
    name: "部门",
    type: "department",
    value: "部门",
    required: false,
  },
  {
    name: "公司",
    type: "company",
    value: "公司",
    required: false,
  },
  {
    name: "地域",
    type: "area",
    value: "生日",
    required: false,
    levels: [
      {
        value: "province",
        label: "省/自治区/直辖市",
        remove: true,
      },
      {
        value: "city",
        label: "市",
        remove: true,
      },
      {
        value: "county",
        label: "区/县",
        remove: true,
      },
      {
        value: "address",
        label: "请输入详细地址",
        remove: true,
      },
    ],
  },
  {
    name: "生日",
    type: "birthday",
    value: "生日",
    required: false,
    format: "Y-m-d",
    formats: [
      {
        value: "Y-m-d",
        label: "显示为日期",
      },
      {
        value: "H:i:s",
        label: "显示为时间",
      },
      {
        value: "Y-m-d H:i:s",
        label: "显示为日期+时间",
      },
    ],
  },
];

export default formData;
