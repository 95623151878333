<template>
  <div class="user-info-create">
    <div class="toggle-penal" v-if="qData">
      <vh-switch
        v-model="qData.form_switch"
        @change="changeOpenStatus"
        class="switch"
        size="mini"
      />
      <span class="toggle-tip"
        >开启后，设置用户信息收集表获取准确信息；用户在答题前须填写本表，若有多个答题，只需填写一次</span
      >
    </div>
    <div
      :class="['edit-panel relative', { disabled: !qData.form_switch }]"
      v-if="qData"
    >
      <pattern-list :qData="qData" @addFiled="addFiled" />
      <div class="form-field-container">
        <!-- 表单编辑组件 -->
        <formField ref="fieldSet" :qData="qData" />
      </div>
    </div>
  </div>
</template>

<script>
import formField from "./formField.vue";
import patternList from "./pattern.vue";
import { QuestionnaireServer } from "@/sdk/src/index.js";
export default {
  components: {
    formField,
    patternList,
  },
  props: {
    examServer: {},
    configs: {
      default: {
        role: 1,
        source_id: undefined,
        source_type: undefined,
      },
    },
  },
  data() {
    return {
      qData: "",
    };
  },
  created() {
    this.examServer
      .getQA({
        source_id: this.configs.source_id,
        source_type: this.configs.source_type,
      })
      .then((res) => {
        console.log(res);
        this.qData = res;
        this.questionnaire = res;
      });
  },
  methods: {
    addFiled({ type, info }) {
      console.log(info);
      this.qData.addQuestion(type, info);
    },
    async changeOpenStatus(val) {
      const res = await this.qData.changeOpenStatus({
        status: val,
        source_id: this.configs.source_id,
      });
      if (res.code == 200) {
        this.$message.success(
          val ? "用户信息收集已开启" : "用户信息收集已关闭"
        );
      } else {
        this.qData.form_switch = !val;
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info-create {
  .toggle-penal {
    box-sizing: border-box;
    height: 36px;
    margin: 16px 0 12px 0;
    padding: 8px 0px;
    line-height: 22px;

    .toggle-tip {
      margin-left: 8px;
      font-size: 14px;
      color: #8c8c8c;
    }
  }
  .edit-panel {
    display: flex;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
  .form-field-container {
    display: block;
    flex: 1;
    width: 0;
    background-color: #f0f0f0;
    padding: 16px;
    justify-content: center;
    position: relative;
  }
}
</style>
