var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fieldSetBox" },
    [
      _c(
        "section",
        { staticClass: "form-item" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("vh-input", {
            staticClass: "intro-input",
            attrs: {
              maxlength: 500,
              autocomplete: "off",
              "show-word-limit": "",
              placeholder: "请输入答题须知",
              type: "textarea",
              autosize: { minRows: 2 },
              resize: "none",
            },
            model: {
              value: _vm.qData.guidelines,
              callback: function ($$v) {
                _vm.$set(_vm.qData, "guidelines", $$v)
              },
              expression: "qData.guidelines",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "list-group",
          attrs: { tag: "ul", handle: ".draggable-btn" },
          on: {
            start: function ($event) {
              _vm.drag = true
            },
            end: function ($event) {
              _vm.drag = false
            },
          },
          model: {
            value: _vm.qData.detail,
            callback: function ($$v) {
              _vm.$set(_vm.qData, "detail", $$v)
            },
            expression: "qData.detail",
          },
        },
        [
          _c(
            "transition-group",
            { attrs: { name: "flip-list", type: "transition" } },
            _vm._l(_vm.qData.detail, function (item, idx) {
              return _c(
                "li",
                { key: item.id, staticClass: "form-item" },
                [
                  _c("p", { staticClass: "form-field-label" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(item.extension.namestr) +
                        "\n          "
                    ),
                    item.required
                      ? _c("span", { staticClass: "whether-need" }, [
                          _vm._v("（必填）"),
                        ])
                      : _c("span", { staticClass: "whether-need" }, [
                          _vm._v("（非必填）"),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("q-" + item.type, {
                    tag: "component",
                    attrs: { data: item },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "control-box" },
                    [
                      _c("i", {
                        staticClass:
                          "icon vh-form-iconfont vh-form-line-delete",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.delQuestion(idx)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass:
                          "icon vh-form-iconfont vh-form-line-rank draggable-btn",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "switch-text" }, [
                        _vm._v("必填项"),
                      ]),
                      _vm._v(" "),
                      _c("vh-switch", {
                        staticClass: "switch",
                        attrs: { size: "mini" },
                        model: {
                          value: item.required,
                          callback: function ($$v) {
                            _vm.$set(item, "required", $$v)
                          },
                          expression: "item.required",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("PrivacyField", { attrs: { qData: _vm.qData } }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "viewItem sureBtn m-t-16" },
        [
          _c(
            "vh-button",
            {
              staticClass: "block ma",
              attrs: { round: "", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "form-field-label" }, [
      _vm._v("\n      答题须知\n      "),
      _c("span", { staticClass: "whether-need" }, [_vm._v("（非必填）")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }