<template>
  <div>
    <!-- 裁剪图片弹窗 -->
    <cropper
      ref="webinarCropper"
      @cropComplete="cropComplete"
      @resetUpload="resetUpload"
    ></cropper>
    <vh-upload
      ref="upload"
      class="drag_upload"
      :action="url"
      :data="saveImageData"
      :show-file-list="false"
      :on-success="uploadSuccess"
      :on-change="changeFile"
      :auto-upload="false"
      :headers="headers"
      name="resfile"
      accept="image/jpg,image/jpeg,image/png,image/gif,image/bmp"
    >
      <div class="upload_content">
        <div class="img_box" v-if="imgUrl"><img :src="imgUrl" alt="" /></div>
        <div class="upload_cover" v-if="imgUrl">
          <p>
            <i class="vh-form-iconfont vh-form-line-refresh-left"></i>
            <span>更换</span>
          </p>
        </div>
        <div class="tip" v-else>
          <p>
            <i class="vh-form-iconfont vh-form-a-line-cloudtoupload"></i>
            建议尺寸：800*450px，小于4M 支持jpg、gif、png、bmp
          </p>
        </div>
      </div>
    </vh-upload>
  </div>
</template>
<script>
import cropper from "./cropper.vue";
import { getEnv } from "@/sdk/api/api-exam.js";
export default {
  components: {
    cropper,
  },
  props: {
    imgUrl: {
      default: "",
    },
  },
  data() {
    return {
      upload_process_data: "",
      headers: {
        "Gray-Id":
          sessionStorage.getItem("userId") ||
          sessionStorage.getItem("initGrayId") ||
          "",
      },
      url:
        getEnv() === "test"
          ? "//test01-saas-api.vhall.com/v3/commons/upload/index"
          : "//saas-api.vhall.com/v3/commons/upload/index",
    };
  },
  computed: {
    saveImageData() {
      let imageData = {
        path: "interacts/exam-imgs",
        type: "image",
      };
      if (this.upload_process_data) {
        imageData.process = this.upload_process_data;
      }
      return imageData;
    },
  },
  methods: {
    uploadSuccess(res) {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
      this.$emit("uploadSuccess", res.data.domain_url);
    },
    changeFile(val) {
      if (val.status != "ready") {
        return;
      }
      const file = val.raw;
      const typeList = ["jpg", "jpeg", "gif", "png", "bmp"];
      console.log(file.type.toLowerCase());
      let typeArr = file.type.toLowerCase().split("/");
      const isType = typeList.includes(typeArr[typeArr.length - 1]);
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isType) {
        this.$message({
          message: `上传封面图片只能是 ${typeList.join("、")} 格式`,
          showClose: true,
          // duration: 0,
          type: "error",
        });
        return false;
      }
      if (!isLt2M) {
        this.$message({
          message: "上传封面图片大小不能超过 4M",
          showClose: true,
          // duration: 0,
          type: "error",
        });
        return false;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.$refs.webinarCropper.showModel(e.target.result);
      };
      // uploadImg({ file }).then((res) => {
      //   if (res.code == 200) {
      //     this.$refs.webinarCropper.showModel("http://" + res.data);
      //   }
      // });
    },
    cropComplete(data, mode) {
      this.upload_process_data = data || "";
      this.loadingInstance = this.$vhLoading({
        lock: true,
        text: "图片上传中...",
      });
      this.$nextTick(() => {
        this.$refs.upload.submit();
      });
    },
    resetUpload() {
      this.$refs["upload"].$el.getElementsByTagName("input")[0].click();
    },
  },
};
</script>
<style scoped lang="scss">
.drag_upload {
  background: #f5f5f5;
  ::v-deep .vh-upload--picture-card {
    width: auto;
    height: auto;
    border: none;
  }
}
.upload_content {
  width: 200px;
  height: 112px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 4px;
  position: relative;
  // overflow: hidden;
  &:hover {
    .upload_cover {
      display: flex;
    }
  }
  .upload_cover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    line-height: 14px;
    p {
      width: 100%;
      text-align: center;
      i {
        font-size: 14px;
        color: #fff;
      }
      span {
        display: block;
        font-size: 14px;
        margin-top: 4px;
        color: #fff;
      }
    }
  }
  .img_box {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .tip {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    padding: 10px;
    i {
      font-size: 30px;
      display: block;
      margin-bottom: 15px;
    }
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #8c8c8c;
    background: #f5f5f5;
    line-height: 20px;
  }
}
</style>
