function ajax(option) {
  let { url, method, async = true, timeout = 5000, params, header } = option;

  const xhr = new XMLHttpRequest();
  // if (timeout) {
  //   xhr.timeout = timeout;
  // }
  return new Promise((resolve, reject) => {
    let data;
    // 成功
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if ((xhr.status >= 200 && xhr.status <= 300) || xhr.status === 304) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject("error");
        }
      }
    };
    // 失败
    xhr.onerror = (error) => {
      reject(error);
    };
    xhr.ontimeout = () => {
      reject("timeout");
    };
    if (header["Content-Type"] === "multipart/form-data") {
      data = new FormData();
      console.log(params);
      for (let key in params) {
        data.append(key, params[key]);
      }
    } else if (
      (header["Content-Type"] || "")
        .toLowerCase()
        .indexOf("application/json") !== -1
    ) {
      header["Content-Type"] = "application/json;charset=UTF-8";
      data = JSON.stringify(params);
    }
    if (
      header["Content-Type"] == "application/x-www-form-urlencoded" ||
      method === "get"
    ) {
      let formBody = [];
      for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      data = formBody;
    }

    console.log(method === "get" ? `${url}?${data}` : url);
    // 建立连接
    xhr.open(method, method === "get" ? `${url}?${data}` : url, async);
    if (header["Content-Type"] == "multipart/form-data") {
      header["Content-Type"] = undefined;
    }

    Object.keys(header).forEach((key) => {
      header[key] != undefined && xhr.setRequestHeader(key, header[key]);
    });
    if (method === "get") {
      xhr.send();
    } else if (method === "post") {
      xhr.send(data);
    }
  });
}
export default ajax;
