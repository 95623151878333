var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "phone-field" },
    [
      _c(
        "vh-input",
        {
          staticClass: "form-field-inner-required",
          attrs: { placeholder: "手机号" },
          model: {
            value: _vm.item.replys,
            callback: function ($$v) {
              _vm.$set(_vm.item, "replys", $$v)
            },
            expression: "item.replys",
          },
        },
        [
          _vm.item.required
            ? _c(
                "i",
                {
                  staticClass: "required",
                  attrs: { slot: "prefix" },
                  slot: "prefix",
                },
                [_vm._v("*")]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-t-12", attrs: { id: "vhall-form-captcha" } },
        [_vm._v("易盾的占位")]
      ),
      _vm._v(" "),
      _c(
        "vh-input",
        {
          staticClass: "m-t-12",
          attrs: { placeholder: "动态验证码" },
          model: {
            value: _vm.item.code,
            callback: function ($$v) {
              _vm.$set(_vm.item, "code", $$v)
            },
            expression: "item.code",
          },
        },
        [
          _c(
            "vh-button",
            {
              class: [{ red: _vm.vertify_pass && _vm.phoneKey }, "vertify"],
              attrs: {
                slot: "suffix",
                type: "text",
                disabled:
                  !_vm.vertify_pass || !_vm.phoneKey || _vm.countDownStatus,
              },
              on: { click: _vm.getCaptcha },
              slot: "suffix",
            },
            [_vm._v("\n      " + _vm._s(_vm.butText) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }