import BaseForm from "../common/baseForm";
import {
  createExam,
  getExamDetail,
  updateExam,
  getExamPreviewInfo,
  getPaperInfo,
  submitExamAnswerInfo,
  submitExamAnswerAll,
  getExamUserScope,
  getExamAnswerInfo,
} from "@/sdk/api/api-exam.js";
import { deepCopy } from "@/common/utils.js";
const SUBMIT = "submit";
const SAVE = "save";
class Exam extends BaseForm {
  constructor({ id, source_id, source_type, role, user_info }) {
    super(id);
    this.role = role;
    this.source_id = source_id;
    this.source_type = source_type;
    if (id) {
      //role 1:完整数据，2.不含答案数据
      if (role == 1) {
        this.role = 1;
        return this.getExam(id);
      }
      if (role == 2) {
        this.role = 2;
        return this.getAnswerExam(id, user_info);
      }
    }

    this.limit_time_switch = 0; //限时开关 0.否 1.是
    this.limit_time = 10; //限时时长
    this.auto_push_switch = false; //自动推送成绩开关 0.否 1.是
    this.display_score = 1; //是否展示成绩
  }
  //获取完整问卷详情
  async getExam(id) {
    const exam = await getExamDetail({ id });
    const { code, data } = exam;
    let examdata = {};
    if (code == 200) {
      examdata = this.translateData(data, 2);
    }
    const examdetail = Object.assign(this, examdata);
    const list = examdetail.detail;
    examdetail.detail = [];
    Array.isArray &&
      list.forEach((q) => {
        this.addQuestion(q.type, q);
      });
    this.setCurIndex(-1);
    return examdetail;
  }
  //获取答题时问卷详情(不含答案)
  async getAnswerExam(id, user_info) {
    const exam = !user_info
      ? await getExamPreviewInfo({ id })
      : await getPaperInfo(
          Object.assign(
            { id, source_id: this.source_id, source_type: this.source_type },
            user_info
          )
        );
    const { code, data } = exam;
    let examdata = {};
    if (code == 200) {
      examdata = this.translateData(data, 2);
    }
    const examdetail = Object.assign(this, examdata);
    const list = examdetail.detail;
    examdetail.detail = [];
    list.forEach((q) => {
      q.answerCallback = this.answerCallback.bind(this);
      this.addQuestion(q.type, q);
    });
    this.setCurIndex(list.length - 1);
    return examdetail;
  }
  //转译前后端数据
  translateData(data, type) {
    const exam = deepCopy(data);
    //前端转服务端
    if (type == 1) {
      exam.question_detail = JSON.stringify(
        exam.detail.map((q) => {
          q.id = "";
          q.extension = JSON.stringify(q.extension);
          q.required = q.required ? "Y" : "N";
          q.verification = q.verification ? "Y" : "N";
          return q;
        })
      );
      exam.detail = null;
      exam.limit_time_switch = exam.limit_time_switch ? 1 : 0;
      exam.auto_push_switch = exam.auto_push_switch ? 1 : 0;
    } else if (type == 2) {
      exam.auto_push_switch = exam.auto_push_switch == 1 ? true : false;
      exam.limit_time_switch = exam.limit_time_switch == 1 ? true : false;
      exam.detail = JSON.parse(exam.question_detail).map((q) => {
        q.extension = JSON.parse(q.extension);
        q.required = q.required == "Y" ? true : false;
        q.verification = q.verification == "Y" ? true : false;
        return q;
      });
      exam.question_detail = null;
    }
    return exam;
  }
  //模拟答题本地计算答题结果
  getLocalResult() {
    let total_score = 0;
    let score = 0;
    let right_num = 0;
    let error_num = 0;
    let unanswer_num = 0;
    let right_rate = "10%";
    this.detail.forEach((q) => {
      total_score += Number(q.score);
      if (q.getResult()) {
        score += Number(q.score);
        right_num++;
      } else if (!q.replys || q.replys.length == 0) {
        unanswer_num++;
      } else {
        error_num++;
      }
    });
    right_rate = (right_num / (right_num + error_num)) * 100;
    return {
      total_score,
      score,
      right_num,
      error_num,
      unanswer_num,
      right_rate,
    };
  }
  //创建或保存编辑
  async save(share_switch) {
    const data = this.translateData(this, 1);
    if (this.id) {
      return await updateExam(data);
    } else {
      console.log(JSON.stringify(this.translateData(this, 1)));
      const question_detail = deepCopy(this.detail);
      question_detail.forEach((q) => {
        q.extension = JSON.stringify(q.extension);
        q.required = q.required ? "Y" : "N";
        q.verification = q.verification ? "Y" : "N";
      });
      return await createExam(
        share_switch
          ? Object.assign(data, { share_switch: 1 })
          : Object.assign(data, {
              source_id: this.source_id,
              source_type: this.source_type,
              share_switch: 0,
            })
      );
    }
  }
  //单题目回答勾子
  answerCallback({ id, answer }) {
    if (this.role != 1) {
      submitExamAnswerInfo({
        paper_id: this.id,
        user_answer:
          typeof answer == "object" ? [...answer].sort().join(",") : answer,
        question_id: id,
      });
    }
  }
  //交卷
  async submitAnswer() {
    if (this.role != 1) {
      return await submitExamAnswerAll({ paper_id: this.id });
    }
    this.$emit(SUBMIT, this);
  }
  //查看成绩组装完整数据
  async getExamUserScope(params) {
    const res = await getExamUserScope({ paper_id: this.id, ...params });
    if (res.code === 200) {
      const { question_detail } = res.data;
      const { user_answer, answer } = JSON.parse(question_detail);
      this.detail.forEach((q) => {
        if (user_answer[q.id]) {
          if (q.type == "checkbox") {
            q.replys = user_answer[q.id].split(",").sort();
          } else {
            q.replys = user_answer[q.id];
          }
        }
        if (answer[q.id]) {
          q.setCurrentAnswer(answer[q.id]);
        }
      });
    }
    return res;
  }
  //获取断点需答记录
  async getExamAnswerInfo() {
    return getExamAnswerInfo({ paper_id: this.id });
  }
}
export default Exam;
