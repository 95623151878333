<template>
  <span class="word-art" :data-text="text"> {{ text }}</span>
</template>

<script>
/**
 * @description 双描边艺术字
 */
export default {
  name: "WordArt",
  props: {
    text: {
      type: String,
      required: true,
      default() {
        return "";
      },
    },
  },
};
</script>

<style lang="scss">
$color: #e9410c;
$shadow: #ffecb7;
.word-art {
  display: inline-block;
  position: relative;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  letter-spacing: 5px;
  transform: matrix(0.99, 0, -0.15, 1, 0, 0);

  &::before,
  &::after {
    content: attr(data-text);
    position: absolute;
    left: 0;
  }
  &::before {
    -webkit-text-stroke: 5px $color;
    z-index: -1;
  }
  &::after {
    -webkit-text-stroke: 8px $shadow;
    z-index: -2;
  }
}
</style>
