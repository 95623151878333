// 深拷贝
export function deepCopy(obj, cache) {
  if (cache === void 0) cache = [];

  // just return if obj is immutable value
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // if obj is hit, it is in circular structure
  var hit = cache.filter(function (c) {
    return c.original === obj;
  })[0];
  if (hit) {
    return hit.copy;
  }

  var copy = Array.isArray(obj) ? [] : {};
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({
    original: obj,
    copy: copy,
  });

  Object.keys(obj).forEach(function (key) {
    copy[key] = deepCopy(obj[key], cache);
  });

  return copy;
}
//生成唯一id
export function getUUID() {
  return Math.random().toString(36).substr(3, 10);
}
export const deepObjectMerge = (target, source) => {
  for (const key in source) {
    if (key.hasOwnProperty) {
      target[key] =
        target[key] && typeof target[key] === "object"
          ? deepObjectMerge(target[key], source[key])
          : (target[key] = source[key]);
    }
  }
  return target;
};
/**
 * @description 节流
 * @param {Function} fn
 * @param {Number} t
 * @returns
 */
export const throttle = (fn, t) => {
  let timer = null;
  return function () {
    if (timer) return;
    fn.apply(this, arguments);
    timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
    }, t);
  };
};
//获取指定时区时间
export const getNowDate = (timeZone) => {
  var timezone = timeZone || 8; //目标时区时间，东八区
  // 本地时间和格林威治的时间差，单位为分钟
  var offset_GMT = new Date().getTimezoneOffset();
  // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
  var nowDate = new Date().getTime();
  var targetDate = nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000;
  return targetDate;
};
