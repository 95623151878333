import BaseInfo from "./BaseInfo";
import { deepObjectMerge } from "@/common/utils.js";
export default class TextInfo extends BaseInfo {
  constructor(options) {
    super(options);
    this.type = "text";
    this.answer = "";
    // 输入框提示
    this.placeholder = "";
    this.detail = {
      // 校验格式
      format: "",
      // 最小字数
      min: "",
      // 最大字数
      max: 100,
      // 正确答案
    };
    deepObjectMerge(this, options);
  }
}
