var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.qData
    ? _c(
        "div",
        { staticClass: "user-info" },
        [
          _c(
            "div",
            { staticClass: "introduce-panel ma" },
            [
              _c("p", { staticClass: "title truncate" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _c(
                "TextEllipsis",
                {
                  staticClass: "introduce",
                  attrs: {
                    text: _vm.qData.guidelines,
                    textClass: "text-ellipsis",
                    isLimitHeight: _vm.ellipsisShow,
                    height: 50,
                  },
                },
                [
                  _vm.ellipsisShow
                    ? _c(
                        "span",
                        {
                          staticClass: "ellipsis-toggle",
                          attrs: { slot: "more" },
                          on: {
                            click: function ($event) {
                              _vm.ellipsisShow = false
                            },
                          },
                          slot: "more",
                        },
                        [_vm._v("\n        ...展开\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.ellipsisShow
                    ? _c(
                        "span",
                        {
                          staticClass: "ellipsis-toggle link",
                          attrs: { slot: "after" },
                          on: {
                            click: function ($event) {
                              _vm.ellipsisShow = true
                            },
                          },
                          slot: "after",
                        },
                        [_vm._v("...收起")]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vh-form",
            {
              ref: "form",
              staticClass: "demo-form-inline m-t-20",
              attrs: {
                "label-position": "top",
                rules: _vm.rules,
                model: _vm.qData,
              },
            },
            [
              _vm._l(_vm.qData.detail, function (item, idx) {
                return _c(
                  "vh-form-item",
                  {
                    key: idx,
                    staticClass: "m-b-16",
                    attrs: {
                      label: _vm.convertLabel(item),
                      prop: `detail.${idx}.replys`,
                      rules: item.rules,
                    },
                  },
                  [
                    _c(_vm.convertFormField(item.extension.name), {
                      tag: "component",
                      staticClass: "vmp-exam-item-comp-pc",
                      attrs: {
                        item: item,
                        index: item.sortNum,
                        examServer: _vm.examServer,
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "vh-form-item",
                [
                  _c(
                    "vh-button",
                    {
                      staticClass: "full-width",
                      attrs: { round: "", type: "primary" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }