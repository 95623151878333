import Vue from "vue";
import radio from "./Radio.vue";
import checkbox from "./Checkbox.vue";
import text from "./Text.vue";
import date from "./Date.vue";
import phone from "./Phone.vue";
import area from "./Area.vue";
[radio, checkbox, text, date, phone, area].forEach((item) => {
  Vue.component(item.name, item);
});
