var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vh-select",
        {
          staticClass: "full-width",
          attrs: { placeholder: "省" },
          on: { change: _vm.changeProvince },
          model: {
            value: _vm.province,
            callback: function ($$v) {
              _vm.province = $$v
            },
            expression: "province",
          },
        },
        _vm._l(_vm.provinces, function (item) {
          return _c("vh-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "vh-select",
        {
          staticClass: "full-width m-t-12",
          attrs: { placeholder: "市" },
          on: { change: _vm.changeCity },
          model: {
            value: _vm.city,
            callback: function ($$v) {
              _vm.city = $$v
            },
            expression: "city",
          },
        },
        _vm._l(_vm.cities, function (item) {
          return _c("vh-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "vh-select",
        {
          staticClass: "full-width m-t-12",
          attrs: { placeholder: "区/县" },
          model: {
            value: _vm.county,
            callback: function ($$v) {
              _vm.county = $$v
            },
            expression: "county",
          },
        },
        _vm._l(_vm.counties, function (item) {
          return _c("vh-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("vh-input", {
        staticClass: "m-t-12",
        attrs: { placeholder: "请输入详细地址" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }