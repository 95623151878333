<template>
  <div class="privacy-filed-edit">
    <span class="label"> 隐私协议 </span>
    <div class="content-box">
      <vh-radio v-model="qData.extension.privacySettings" label="1"
        >展示系统自带协议</vh-radio
      >
      <div class="preview">
        <span class="prefix"> 预览效果：</span>
        提交并同意
        <a target="_blank" href="https://t.e.vhall.com/v3/privacyPolicy"
          >《隐私政策》</a
        >
        及
        <a target="_blank" href="https://t.e.vhall.com/v3/privacyUPo"
          >《用户保护协议》</a
        >
      </div>
      <vh-radio v-model="qData.extension.privacySettings" label="2"
        >自定义协议</vh-radio
      >
      <vh-button type="text" class="set-btn" @click="openPrivacyDialog"
        >去设置</vh-button
      >
      <div class="preview">
        <span class="prefix"> 预览效果：</span>
        <span v-html="customPrivacy"></span>
      </div>
    </div>
    <customPrivacy
      ref="dialog"
      v-if="showPrivacyDialog"
      :showPrivacyDialog.sync="showPrivacyDialog"
      :privacyDate="qData.extension.privacyDate"
      @savePrivacy="savePrivacy"
    />
  </div>
</template>

<script>
import customPrivacy from "./settings.vue";
export default {
  name: "PrivacyFieldEdit",
  components: {
    customPrivacy,
  },
  props: {
    qData: {},
  },
  data() {
    return {
      showPrivacyDialog: false,
      customPrivacy:
        '<a target="_blank" href="https://e.vhall.com/v3/privacyUPo">《隐私声明》</a>保护您所填写的所有信息',
    };
  },
  created() {
    this.$set(
      this.qData.extension,
      "privacySettings",
      this.qData.extension.privacySettings || "1"
    );
    this.$set(
      this.qData.extension,
      "privacyDate",
      this.qData.extension.privacyDate || {}
    );
  },
  methods: {
    openPrivacyDialog() {
      this.showPrivacyDialog = true;
    },
    savePrivacy(data) {
      this.qData.extension.privacyDate = data;
      console.log(data, "savePrivacy");
    },
  },
};
</script>

<style lang="scss" scoped>
.privacy-filed-edit {
  padding: 20px 24px;
  background: #fff;

  .label {
    float: left;
    font-size: 16px;
    color: #1a1a1a;
    clear: both;
  }
  .content-box {
    margin-left: 82px;
    > label,
    > div {
      margin-bottom: 10px;
    }
  }
  .set-btn {
    color: #3562fa;
  }
  .preview :deep {
    padding: 10px 24px;
    background: #f7f7f7;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
    .prefix {
      color: #999;
    }
    a,
    a:visited {
      color: #3562fa;
    }
  }
}
</style>
