var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "title-input-container" },
        [
          _c("q-title-input", {
            model: {
              value: _vm.data.title,
              callback: function ($$v) {
                _vm.$set(_vm.data, "title", $$v)
              },
              expression: "data.title",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("vh-input", {
        staticClass: "intro-input",
        attrs: {
          autocomplete: "off",
          "show-word-limit": "",
          type: "text",
          disabled: "",
        },
        model: {
          value: _vm.data.value,
          callback: function ($$v) {
            _vm.$set(
              _vm.data,
              "value",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "data.value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }