import { render, staticRenderFns } from "./ribbon.vue?vue&type=template&id=8869ea94&"
import script from "./ribbon.vue?vue&type=script&lang=js&"
export * from "./ribbon.vue?vue&type=script&lang=js&"
import style0 from "./ribbon.vue?vue&type=style&index=0&id=8869ea94&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.0_m252fu66fivcrdajfhoxhvxrne/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports