var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      class: [
        "exam-model-panel",
        _vm.fullMarks > 0 && _vm.answerType == 2
          ? "exam-computed-transcript"
          : "exam-computed-other",
      ],
    },
    [
      _vm.answerType == 1 || _vm.answerType == 3
        ? _c(
            "div",
            { staticClass: "exam-execute-header" },
            [
              _c(
                "div",
                { staticClass: "exam-execute-title" },
                [
                  [
                    _c("div", { staticClass: "exam-execute--time" }, [
                      _c("img", {
                        staticClass: "exam_time__icon",
                        attrs: {
                          src: "https://s3.e.vhall.com/common-static/vhall-form/images/icon_exam_time.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _vm.answerType != 3
                        ? _c(
                            "div",
                            { staticClass: "exam_time__text" },
                            [
                              _vm.answerFormat &&
                              (_vm.answerFormat.hour >= 0 ||
                                _vm.answerFormat.minute >= 0)
                                ? [
                                    _vm.questionnaire &&
                                    _vm.questionnaire.limit_time_switch > 0
                                      ? _c("ul", [
                                          _c(
                                            "li",
                                            { staticClass: "exam-css-timer" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.answerFormat.hour > 0
                                                      ? _vm.answerFormat.hour >
                                                        9
                                                        ? _vm.answerFormat.hour
                                                        : `0${_vm.answerFormat.hour}`
                                                      : "00"
                                                  ) +
                                                  ":" +
                                                  _vm._s(
                                                    _vm.answerFormat.minute > 0
                                                      ? _vm.answerFormat
                                                          .minute > 9
                                                        ? _vm.answerFormat
                                                            .minute
                                                        : `0${_vm.answerFormat.minute}`
                                                      : "00"
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _c(
                                          "span",
                                          { staticClass: "exam-css-timer" },
                                          [_vm._v(_vm._s(_vm.$t("exam.1001")))]
                                        ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c("div", { staticClass: "exam_time__text" }, [
                            _vm.answerFormat &&
                            (_vm.answerFormat.hour > 0 ||
                              _vm.answerFormat.minute > 0)
                              ? _c("ul", [
                                  _c("li", { staticClass: "exam-css-timer" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.answerFormat.hour > 0
                                            ? _vm.answerFormat.hour > 9
                                              ? _vm.answerFormat.hour
                                              : `0${_vm.answerFormat.hour}`
                                            : "00"
                                        ) +
                                        ":" +
                                        _vm._s(
                                          _vm.answerFormat.minute > 0
                                            ? _vm.answerFormat.minute > 9
                                              ? _vm.answerFormat.minute
                                              : `0${_vm.answerFormat.minute}`
                                            : "00"
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                    ]),
                  ],
                  _vm._v(" "),
                  _vm.questionnaire && _vm.totalPage
                    ? _c("div", { staticClass: "exam-execute--status" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.curPage) + "/" + _vm._s(_vm.totalPage)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "exam-execute--close" }, [
                    _vm._v(" "),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _vm.configs.pageSize > 0 && _vm.totalPage > 1
                ? _c("vh-progress", {
                    staticClass: "exam-zdy-progress",
                    attrs: {
                      percentage: _vm.percentage,
                      "show-text": false,
                      "stroke-width": 3,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "exam-padding-line" }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.questionnaire && (_vm.answerType == 1 || _vm.answerType == 3)
        ? _c("exam-info", {
            ref: "examInfoDom",
            staticClass: "exam-execute-body",
            attrs: {
              examServer: _vm.examServer,
              configs: _vm.configs,
              questionList: _vm.questionList,
              answerType: _vm.answerType,
              curPage: _vm.curPage,
              totalPage: _vm.totalPage,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.answerType == 2 && _vm.result
        ? _c("exam-result", {
            ref: "examResultDom",
            attrs: { result: _vm.result },
            on: { toResult: _vm.showExamResultInfo },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }