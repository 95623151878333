var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-field" },
    [
      _c(
        "vh-input",
        {
          class: {
            "form-field-inner-required": _vm.item.required,
          },
          attrs: { placeholder: _vm.item.title },
          model: {
            value: _vm.item.replys,
            callback: function ($$v) {
              _vm.$set(_vm.item, "replys", $$v)
            },
            expression: "item.replys",
          },
        },
        [
          _vm.item.required
            ? _c(
                "i",
                {
                  staticClass: "required",
                  attrs: { slot: "prefix" },
                  slot: "prefix",
                },
                [_vm._v("*")]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }