<template>
  <div :class="['animation-wrap', score == 2 ? '' : 'zero']">
    <span class="word-wrap">
      <WordArt :text="title" />
      <img :src="star1" alt="" class="star star-1" />
      <img :src="star2" alt="" class="star star-2" />
      <img :src="star1" alt="" class="star star-3" />
    </span>
    <Ribbon />
    <img :src="trophyImg" class="trophy" />
  </div>
</template>

<script>
import trophyImg from "@ui/assets/image/trophy.png";
import Ribbon from "./ribbon.vue";
import WordArt from "@ui/components/common/wordart.vue";
import star1 from "@ui/assets/image/star/star-1.png";
import star2 from "@ui/assets/image/star/star-2.png";
export default {
  name: "trophy",
  components: {
    Ribbon,
    WordArt,
  },
  props: {
    score: {},
  },
  data() {
    return {
      trophyImg,
      star1,
      star2,
    };
  },
  computed: {
    title() {
      return this.score == 2 ? this.$t("exam.1021") : this.$t("exam.1022");
    },
  },
};
</script>

<style lang="scss">
.animation-wrap {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 150px;
  height: 105px;
  text-align: center;

  &.zero {
    filter: grayscale(100%);
  }
}
.word-wrap {
  position: relative;
}
.star {
  position: absolute;
  object-fit: cover;
}
.star-1 {
  width: 8px;
  height: 10px;
  top: -8px;
  left: -15px;
}
.star-2 {
  width: 8px;
  height: 11px;
  top: -6px;
  right: -15px;
}
.star-3 {
  width: 11px;
  height: 14px;
  top: 7px;
  right: -8px;
}
.trophy {
  position: absolute;
  width: 62px;
  height: 72px;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
}
</style>
