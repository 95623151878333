var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-info-create" }, [
    _vm.qData
      ? _c(
          "div",
          { staticClass: "toggle-penal" },
          [
            _c("vh-switch", {
              staticClass: "switch",
              attrs: { size: "mini" },
              on: { change: _vm.changeOpenStatus },
              model: {
                value: _vm.qData.form_switch,
                callback: function ($$v) {
                  _vm.$set(_vm.qData, "form_switch", $$v)
                },
                expression: "qData.form_switch",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "toggle-tip" }, [
              _vm._v(
                "开启后，设置用户信息收集表获取准确信息；用户在答题前须填写本表，若有多个答题，只需填写一次"
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.qData
      ? _c(
          "div",
          {
            class: [
              "edit-panel relative",
              { disabled: !_vm.qData.form_switch },
            ],
          },
          [
            _c("pattern-list", {
              attrs: { qData: _vm.qData },
              on: { addFiled: _vm.addFiled },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-field-container" },
              [
                _c("formField", {
                  ref: "fieldSet",
                  attrs: { qData: _vm.qData },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }