var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "title-input-container" },
      [
        _c("q-title-input", {
          attrs: { "q-placeholder": _vm.data?.extension?.placeholder },
          model: {
            value: _vm.data.title,
            callback: function ($$v) {
              _vm.$set(_vm.data, "title", $$v)
            },
            expression: "data.title",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-field-extra-rule" },
      _vm._l(_vm.levels, function (level, index) {
        return _c(
          "div",
          { key: index, staticClass: "area-level" },
          [
            _c(
              "span",
              { staticClass: "input-wrap" },
              [
                _c("vh-input", {
                  staticClass: "intro-input",
                  attrs: { value: level.label, type: "text", disabled: "" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            index != 0
              ? _c("vh-switch", {
                  staticClass: "switch",
                  on: {
                    change: function ($event) {
                      return _vm.changeLevel(level.isRemove, index)
                    },
                  },
                  model: {
                    value: level.isRemove,
                    callback: function ($$v) {
                      _vm.$set(level, "isRemove", $$v)
                    },
                    expression: "level.isRemove",
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }