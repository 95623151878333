import BaseInfo from "./BaseInfo";
import { deepObjectMerge } from "@/common/utils.js";
export default class AreaInfo extends BaseInfo {
  constructor(options) {
    super(options);
    // 类型
    this.type = "area";

    this.replay = "";
    this.detail = {
      level: 4,
    };
    deepObjectMerge(this, options);
  }
}
