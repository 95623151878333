<template>
  <ul class="user-info-create-pattern">
    <li
      :class="[
        'pattern-item',
        activedList.includes(item.options.info.extension.name) ? 'actived' : '',
      ]"
      v-for="(item, index) in patternTypes"
      :key="index"
      @click="addFiled(item)"
    >
      <i :class="['vh-form-iconfont  icon ', `${item.icon}`]" />
      <span class="pattern-label">{{ item.name }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "UserInfoPattern",
  props: {
    qData: {},
  },
  data() {
    return {
      patternTypes: [
        {
          icon: "icon-fill-name",
          name: "姓名",
          options: {
            type: "text",
            info: {
              title: "姓名",
              extension: {
                namestr: "姓名",
                name: 1,
              },
            },
          },
        },
        {
          icon: "icon-fill-sex",
          name: "性别",
          options: {
            type: "radio",
            info: {
              title: "性别",
              detail: {
                list: [
                  { key: 1, value: "男" },
                  { key: 2, value: "女" },
                ],
              },
              extension: {
                namestr: "性别",
                name: 2,
              },
            },
          },
        },
        {
          icon: "icon-fill-birthday",
          name: "生日",
          options: {
            type: "date",
            info: {
              title: "生日",
              extension: {
                namestr: "生日",
                name: 3,
              },
            },
          },
        },
        {
          icon: "icon-fill-mobile",
          name: "手机",
          options: {
            type: "text",
            info: {
              title: "手机",
              extension: {
                namestr: "手机",
                name: 4,
                codeSwitch: 1,
              },
            },
          },
        },
        {
          icon: "icon-fill-email",
          name: "邮箱",
          options: {
            type: "text",
            info: {
              title: "邮箱",
              extension: {
                namestr: "邮箱",
                name: 5,
              },
            },
          },
        },
        {
          icon: "icon-fill-education",
          name: "教育水平",
          options: {
            type: "text",
            info: {
              title: "教育水平",
              extension: {
                namestr: "教育水平",
                name: 6,
              },
            },
          },
        },
        {
          icon: "icon-fill-industry",
          name: "行业",
          options: {
            type: "text",
            info: {
              title: "行业",
              extension: {
                namestr: "行业",
                name: 7,
              },
            },
          },
        },
        {
          icon: "icon-fill-region",
          name: "地域",
          options: {
            type: "area",
            info: {
              title: "地域",
              extension: {
                namestr: "地域",
                name: 8,
              },
            },
          },
        },
        {
          icon: "icon-fill-company",
          name: "公司",
          options: {
            type: "text",
            info: {
              title: "公司",
              extension: {
                namestr: "公司",
                name: 9,
              },
            },
          },
        },
        {
          icon: "icon-fill-company",
          name: "部门",
          options: {
            type: "text",
            info: {
              title: "部门",
              extension: {
                name: 10,
              },
            },
          },
        },
        {
          icon: "",
          name: "职务",
          options: {
            type: "text",
            info: {
              title: "职务",
              extension: {
                name: 11,
              },
            },
          },
        },
        {
          icon: "",
          name: "工号",
          options: {
            type: "text",
            info: {
              title: "工号",
              extension: {
                name: 12,
              },
            },
          },
        },
      ],
    };
  },
  computed: {
    activedList() {
      return this.qData.detail.map((item) => {
        return item.extension.name;
      });
    },
  },
  methods: {
    addFiled(item) {
      this.$emit("addFiled", item.options);
    },
  },
};
</script>
<style lang="scss" scoped>
.user-info-create-pattern {
  width: 120px;
  background: #fff;
  height: 100%;
  overflow-y: auto;
  &::after {
    clear: both;
  }
  .pattern-item {
    box-sizing: border-box;
    padding: 10px 0;
    height: 40px;
    margin-left: 22px;
    cursor: pointer;
    font-size: 14px;
    color: #1a1a1a;
    .icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      font-size: 14px;
    }
    .pattern-label {
      margin-left: 5px;
    }

    &.actived {
      color: #bfbfbf;
      pointer-events: none;
    }
    &:hover {
      color: #fb3a32;
    }
  }
}
</style>
