var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        id: "exam-score-svg",
        width: "200px",
        height: "110px",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M5 100\n      A 75 75 , 0, 1, 1,195 100",
          fill: "none",
          stroke: "#EDECEE",
          "stroke-linecap": "round",
          "stroke-width": "6",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M5 100\n      A 75 75 , 0, 1, 1,195 100",
          fill: "none",
          stroke: "#33A0FF",
          "stroke-linecap": "round",
          "stroke-width": "10",
          "stroke-dasharray": _vm.length,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }