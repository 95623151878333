export default {
  exam: {
    1001: "Unlimited",
    1002: "Single",
    1003: "Multiple",
    1004: "Last",
    1005: "Next",
    1006: "Submit",
    1007: "Correct",
    1008: "Error",
    1009: "Unanswered",
    1010: "Click for details",
    1011: "Total score",
    1012: "",
    1013: "Accuracy",
    1014: "Answer",
    1015: "Right answer",
    1016: "Your answer",
    1017: "Great! Your answer is exactly right!",
    1018: "Unfortunately,your answer is wrong,keep trying!",
    1019: "Correct",
    1020: "Error",
    1021: "Full score",
    1022: "0",
    1023: "Close",
  },
};
