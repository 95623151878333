<template>
  <div>
    <vh-select
      placeholder="省"
      @change="changeProvince"
      v-model="province"
      class="full-width"
    >
      <vh-option
        v-for="item in provinces"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </vh-option>
    </vh-select>
    <vh-select
      placeholder="市"
      @change="changeCity"
      v-model="city"
      class="full-width m-t-12"
    >
      <vh-option
        v-for="item in cities"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </vh-option>
    </vh-select>
    <vh-select placeholder="区/县" v-model="county" class="full-width m-t-12">
      <vh-option
        v-for="item in counties"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </vh-option>
    </vh-select>
    <vh-input class="m-t-12" placeholder="请输入详细地址"> </vh-input>
  </div>
</template>
<script>
/**
 * @description 地域题
 */
import { getArea } from "@/sdk/api/api-exam.js";
export default {
  name: "a-area",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      province: "",
      city: "",
      county: "",
      area: "",
      provinces: [],
      cities: [],
      counties: [],
    };
  },
  methods: {
    changeProvince(value) {
      this.cities = this.area.cities[value];
      if (this.showCity) {
        this.cityVal = this.cities[0].value;
        this.counties = this.area.counties[this.cityVal];
        this.countyVal = "";
      }
    },
    changeCity(value) {
      this.counties =
        this.area.counties[value] || this.area.counties[this.cityVal];
      this.countyVal = this.counties[0].value;
    },
  },
  created() {
    getArea().then((res) => {
      this.area = res;
      this.provinces = this.area.provinces;
    });
  },
};
</script>

<style lang="scss" scoped>
.area-field {
}
</style>
