import BaseInfo from "./BaseInfo";
import { deepObjectMerge } from "@/common/utils.js";
export default class DateInfo extends BaseInfo {
  constructor(options) {
    super(options);
    // 类型
    this.type = "date";
    this.detail = {
      // 校验格式
      fomart: "date",
    };
    this.extension = {};
    deepObjectMerge(this, options);
  }
}
