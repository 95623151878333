<template>
  <div :class="['exam-create-title-bar', `client-${client}`]">
    <div>
      <div class="title-input-container">
        <q-title-input
          class="exam-title"
          ref="input"
          v-model="inputVal"
          :maxlength="50"
        />
      </div>
      <p class="exam-statistics">
        <span>
          题数
          <span class="exam-statistics-number">
            {{ totalNum }}
          </span>
        </span>
        <span>
          文字单选题
          <span class="exam-statistics-number">
            {{ singleTextQuestionsNum }}
          </span>
        </span>
        <span
          >文字多选题
          <span class="exam-statistics-number">
            {{ multiTextQuestionsNum }}
          </span>
        </span>
        <span>
          图片单选题
          <span class="exam-statistics-number">
            {{ singlePhotoQuestionsNum }}
          </span>
        </span>
        <span>
          图片多选题
          <span class="exam-statistics-number">
            {{ multiPhotoQuestionsNum }}
          </span>
        </span>
      </p>
    </div>
    <div class="exam-statistics-score" v-show="totalScore">
      <p class="tip">总分</p>
      <span class="score">{{ totalScore }}</span>
    </div>
  </div>
</template>

<script>
/**
 * 创建问卷的标题
 */
import qTitleInput from "@ui/components/common/qTitleInput.vue";
export default {
  name: "ExamCreateTitleBar",
  components: {
    qTitleInput,
  },
  props: {
    // 标题
    value: {
      type: String,
      required: true,
    },
    // 总分数
    totalScore: {
      type: Number,
      default() {
        return 0;
      },
    },
    // 题数
    totalNum: {
      type: Number,
      default() {
        return 0;
      },
    },
    singleTextQuestionsNum: {
      type: Number,
      default() {
        return 0;
      },
    },
    multiTextQuestionsNum: {
      type: Number,
      default() {
        return 0;
      },
    },
    singlePhotoQuestionsNum: {
      type: Number,
      default() {
        return 0;
      },
    },
    multiPhotoQuestionsNum: {
      type: Number,
      default() {
        return 0;
      },
    },
    client: {
      type: String,
      default() {
        return "live";
      },
    },
  },
  data() {
    return {
      inputVal: "",
    };
  },
  watch: {
    inputVal(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.inputVal = this.value; // 不能绑定prop
  },
};
</script>

<style lang="scss">
.exam-create-title-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 68px; //防止塌方
  background: #fff;
  // border-radius: 4px;
  &.client-live {
    padding-bottom: 15px;
  }
  &.client-console {
    // 控制端
    padding: 24px;
    height: 118px;
    border-radius: 4px 4px 0px 0px;
    position: relative; //提高文档流层级,显示阴影
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }
  .exam-statistics {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }

  .exam-statistics-number {
    margin-right: 12px;
    color: #fb2626;
  }
  .title-input-container {
    display: inline-block;
    width: 800px;
  }
  .exam-title {
    margin-left: -12px;
    width: 850px;
    .q-title-text,
    .inner_input .vh-textarea__inner {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #262626;
    }
  }
  .exam-statistics-score {
    display: inline-block;
    box-sizing: border-box;
    min-width: 68px;
    height: 68px;
    background: #fb2626;
    border-radius: 4px;
    color: #fff;
    padding: 8px;
    text-align: center;
    .tip {
      font-size: 14px;
    }
    .score {
      display: inline-block;
      margin-top: 5px;
      font-family: "D-DIN";
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
    }
  }
}
</style>
