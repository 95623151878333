var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "vmp-transcripts",
        _vm.fullMarks > 0 ? "vmp-transcripts-extreme" : "vmp-transcripts-other",
      ],
    },
    [
      _vm.fullMarks > 0
        ? [
            _c("trophy", {
              staticClass: "trophy-wrap",
              attrs: { score: _vm.fullMarks },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
          ]
        : _c("div", [
            _c(
              "div",
              { staticClass: "animation-panel ma" },
              [
                _c("img", {
                  staticClass: "annular-bg",
                  attrs: { src: _vm.annularImg, alt: "" },
                }),
                _vm._v(" "),
                _c("annular"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "transcripts-score absolute text-center" },
                  [
                    _c("span", [
                      _c("span", { staticClass: "std-text-pro d-din" }, [
                        _vm._v(
                          _vm._s(
                            _vm.result.total_score > 0
                              ? _vm.result.score
                              : Number(_vm.result.right_rate).toFixed(1)
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("sub", { staticClass: "std-text-aux" }, [
                        _vm._v(
                          _vm._s(
                            _vm.result.total_score > 0
                              ? _vm.$t("exam.1012")
                              : "%"
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "total-wrap inline-block" }, [
                      _c("span", { staticClass: "std-text-aux total" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.result.total_score > 0
                                ? `${_vm.$t("exam.1011")}:${
                                    _vm.result.total_score
                                  }`
                                : _vm.$t("exam.1013")
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "statistics-panel" }, [
              _c("div", { staticClass: "statistics-item" }, [
                _c("p", { staticClass: "count correct" }, [
                  _vm._v(_vm._s(_vm.result.right_num)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "tip" }, [
                  _vm._v(_vm._s(_vm.$t("exam.1007"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "statistics-item" }, [
                _c("p", { staticClass: "count error" }, [
                  _vm._v(_vm._s(_vm.result.error_num)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "tip" }, [
                  _vm._v(_vm._s(_vm.$t("exam.1008"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "statistics-item" }, [
                _c("p", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.result.unanswer_num)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "tip" }, [
                  _vm._v(_vm._s(_vm.$t("exam.1009"))),
                ]),
              ]),
            ]),
          ]),
      _vm._v(" "),
      _c(
        "vh-button",
        {
          staticClass: "ma block button",
          attrs: { type: "primary", round: "" },
          on: { click: _vm.toResult },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("exam.1010")) + "\n  ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }