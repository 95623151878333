import Exam from "./examClass";
import Questionnaire from "./qaClass";
import * as API from "@/sdk/api/api-exam.js";
import setHeaders from "@/sdk/api/api-exam.js";
import BaseServer from "../common/baseServer.js";
import { setEnv, checkExam } from "@/sdk/api/api-exam.js";
const events = {
  SUBMITEDIT: "SUBMITEDIT",
  SUBMITANSWER: "SUBMITANSWER",
};
class ExamServer extends BaseServer {
  constructor({ role, source_type, source_id, accountInfo, env }) {
    if (ExamServer?.instance?.constructor === ExamServer) {
      return ExamServer.instance;
    }
    super();
    //角色1:发卷人，2:答卷人
    this.role = role;
    //渠道
    this.source_type = source_type;
    this.source_id = source_id;
    //初始化的鉴权信息
    const { biz_permission_key, app_id, csd_token, platform } = accountInfo;
    this.initInfo = { biz_permission_key, app_id, csd_token, platform };
    setHeaders(this.initInfo);
    setEnv(env);
    // setHeaders(options);
    //对外接口方法
    this.api = API;
    this.events = events;
    ExamServer.instance = this;
  }
  getExam(options) {
    Object.assign(options, {
      role: this.role,
      source_id: this.source_id || options.source_id,
      source_type: this.source_type,
    });
    return Promise.resolve(new Exam(options));
  }
  getQA() {
    return Promise.resolve(
      new Questionnaire({
        source_id: this.source_id,
        source_type: this.source_type,
        role: this.role,
      })
    );
  }
  async checkUserInfo(options) {
    return await checkExam({
      source_id: this.source_id,
      source_type: this.source_type,
      ...options,
    });
  }
}
export default ExamServer;
