<template>
  <div class="phone-field">
    <vh-input
      v-model="item.replys"
      class="form-field-inner-required"
      placeholder="手机号"
    >
      <i slot="prefix" v-if="item.required" class="required">*</i>
    </vh-input>
    <div class="m-t-12" id="vhall-form-captcha">易盾的占位</div>
    <vh-input v-model="item.code" placeholder="动态验证码" class="m-t-12">
      <vh-button
        slot="suffix"
        type="text"
        :disabled="!vertify_pass || !phoneKey || countDownStatus"
        :class="[{ red: vertify_pass && phoneKey }, 'vertify']"
        @click="getCaptcha"
      >
        {{ butText }}
      </vh-button>
    </vh-input>
  </div>
</template>
<script>
/**
 * @description 输入题
 */

import loadjs from "loadjs";

const CaptchaUrl = "//cnstatic01.e.vhall.com/3rdlibs/yidun/captcha/load.min.js";
export default {
  name: "a-phone",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    examServer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      phoneKey: "",
      phoneValide: true,
      form: {
        phone: "",
      },
      countDownStatus: false,
      vertify_pass: false,
      rules: {
        phone: [{ validator: this.rexPhone(), trigger: "blur" }],
      },
      capInstance: null,
      timer: null,
      countTime: 60,
      timerTip: null,
      cap: null,
      butText: "发送验证码",
    };
  },
  watch: {
    "item.replys"(value) {
      console.log("🚀 ~ file: Phone.vue ~ line 64 ~ value", value);
      if (value === "" || !value) {
        this.vertify_pass = false;
      } else {
        if (value !== "" && !/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(value)) {
          // callback(new Error("请输入正确的手机号"));
          // callback(new Error(this.$t("login.login_1056")));
          this.vertify_pass = false;
        } else {
          this.vertify_pass = true;
          // callback();
        }
      }
    },
  },
  async mounted() {
    await this.initNECaptcha();
  },
  created() {
    this.$set(this.item, "code", "");
  },
  methods: {
    // 加载易盾包
    loadCaptcha() {
      return new Promise((resolve) => {
        if (window.initNECaptcha) {
          resolve();
        } else {
          loadjs(CaptchaUrl, resolve);
        }
      });
    },
    // 易盾初始化
    async initNECaptcha() {
      await this.loadCaptcha();
      const _this = this;
      initNECaptcha(
        {
          captchaId: "b7982ef659d64141b7120a6af27e19a0",
          element: "#vhall-form-captcha",
          // width: 260,
          // height: 30,
          mode: "float",
          onReady: function (instance) {
            _this.capInstance = instance;
          },
          onVerify: function (err, data) {
            if (data) {
              _this.phoneKey = data.validate;
              console.log(_this.phoneKey, "_this.phoneKey");
            }
            if (err) {
              console.log(err);
            }
          },
          onError: function (e) {
            console.error(e);
          },
        },
        function onload(instance) {
          _this.cap = instance;
        }
      );
    },
    // 重置易盾
    refreshNECaptcha() {
      this.capInstance && this.capInstance.refresh();
    },
    // 获取验证码
    getCaptcha() {
      if (this.countDownStatus) return;
      console.log(
        "🚀 ~ file: Phone.vue ~ line 157 ~ getCaptcha ~ examServer",
        this.examServer
      );
      this.examServer.api
        .sendExamPhone({
          phone: this.item.replys,
          scene: 1,
          msg_template_id: "a6f7552d669145f999b77a462f62b3f5",
          msg_sign_id: "d54a2a1e59a54dc5bfdd9c74822e9974",
        })
        .then((res) => {
          console.log("🚀 ~ file: Phone.vue ~ line 142 ~ .then ~ res", res);
          if (res.code === 200) {
            this.initInterval();
            this.timer = setInterval(() => {
              this.countDownStatus = true;
              this.countTime--;
              this.butText = `${this.countTime}s`;
              if (this.countTime === 0) {
                this.initInterval();
                this.refreshNECaptcha();
              }
            }, 1000);
          }
        });
    },
    // 初始化定时器倒计时
    initInterval() {
      clearInterval(this.timer);
      this.timer = null;
      this.countDownStatus = false;
      this.countTime = 60;
      this.butText = "发送验证码";
    },
    rexPhone() {
      return (rule, value, callback) => {
        if (value === "" || !value) {
          // callback(new Error(this.$t("login.login_1044")));
          callback(new Error("请输入手机号"));
        } else {
          if (value !== "" && !/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(value)) {
            callback(new Error("请输入正确的手机号"));
            // callback(new Error(this.$t("login.login_1056")));
          } else {
            this.vertify_pass = true;
            callback();
          }
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-field {
  .vertify {
    margin-top: 14px;
    display: flex;

    &.red {
      // color: #fff;
      // border: 1px solid #fb3a32;
      // background-color: #fb3a32;
    }
  }
}
</style>
