var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vh-dialog",
    {
      attrs: {
        title: "图片处理",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        width: "480px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "cropper_content" }, [
        _c("div", { staticClass: "cropper_content_wrapper" }, [
          _vm.isShowImages
            ? _c("div", { staticClass: "cropper_image" }, [
                _c("img", { attrs: { src: _vm.url, alt: "" } }),
              ])
            : _c(
                "div",
                { staticClass: "cropper_content_box" },
                [
                  _c("vue-cropper", {
                    ref: "cropper",
                    staticClass: "cropper_img",
                    style: { width: "400px", height: "234px" },
                    attrs: {
                      src: _vm.url,
                      "aspect-ratio": _vm.ratio,
                      viewMode: 1,
                      autoCropArea: 1,
                    },
                  }),
                ],
                1
              ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cropper_content_btn" }, [
          _c(
            "div",
            [
              _vm.isShowImages
                ? _c(
                    "vh-button",
                    {
                      attrs: { size: "medium", plain: "", round: "" },
                      on: { click: _vm.changeValue },
                    },
                    [_vm._v("裁剪")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowImages
                ? _c(
                    "vh-button",
                    {
                      attrs: { size: "medium", plain: "", round: "" },
                      on: { click: _vm.changeValue },
                    },
                    [_vm._v("取消裁剪")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "vh-button",
                {
                  attrs: { size: "medium", plain: "", type: "text" },
                  on: { click: _vm.resetCropper },
                },
                [_vm._v("重新上传")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "vh-button",
                {
                  attrs: { size: "medium", type: "primary", round: "" },
                  on: { click: _vm.cropperSure },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "vh-button",
                {
                  attrs: { size: "medium", ghost: "", type: "info", round: "" },
                  on: { click: _vm.cancelCropper },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }