<template>
  <div class="fieldSetBox">
    <section class="form-item">
      <p class="form-field-label">
        答题须知
        <span class="whether-need">（非必填）</span>
      </p>
      <vh-input
        :maxlength="500"
        autocomplete="off"
        v-model="qData.guidelines"
        show-word-limit
        placeholder="请输入答题须知"
        type="textarea"
        :autosize="{ minRows: 2 }"
        resize="none"
        class="intro-input"
      />
    </section>
    <!-- 表单名称、表单简介与表单头图为固定字段 -->
    <draggable
      class="list-group"
      tag="ul"
      handle=".draggable-btn"
      v-model="qData.detail"
      @start="drag = true"
      @end="drag = false"
    >
      <!-- 加上v-model即可排序后实时更新数据 -->
      <transition-group name="flip-list" type="transition">
        <li
          class="form-item"
          v-for="(item, idx) of qData.detail"
          :key="item.id"
        >
          <p class="form-field-label">
            {{ item.extension.namestr }}
            <span v-if="item.required" class="whether-need">（必填）</span>
            <span v-else class="whether-need">（非必填）</span>
          </p>
          <component :is="'q-' + item.type" :data="item" />
          <div class="control-box">
            <i
              class="icon vh-form-iconfont vh-form-line-delete"
              @click.stop="delQuestion(idx)"
            />
            <i class="icon vh-form-iconfont vh-form-line-rank draggable-btn" />
            <span class="switch-text">必填项</span>
            <vh-switch v-model="item.required" class="switch" size="mini" />
          </div>
        </li>
      </transition-group>
    </draggable>
    <!-- 隐私设置 -->
    <PrivacyField :qData="qData" />
    <section class="viewItem sureBtn m-t-16">
      <vh-button round class="block ma" type="primary" @click="save"
        >保存</vh-button
      >
    </section>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import mockData from "../mockData";
import PrivacyField from "@ui/components/question/edit/Privacy/index.vue";
import "@ui/components/question/edit/index.js";
export default {
  components: {
    draggable,
    PrivacyField,
  },
  props: {
    qData: {},
    questionArr: {
      type: Array,
      default: () => [],
    },
    baseInfo: {
      type: Object,
    },
    signUpSwtich: {
      type: Boolean,
    },
    regionalOptions: {
      type: Object,
    },
  },
  data() {
    return {
      intro: "", // 基本信息——表单简介
      drag: false,
      formData: mockData,
    };
  },
  methods: {
    delQuestion(idx) {
      this.qData.deleteQuestion(idx);
    },
    async save() {
      const res = await this.qData.save();
      if (res.code == 200) {
        this.$message.success("保存成功");
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 题目

.form-item {
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 24px;
  background: #fff;
  border: 1px solid;
  border-color: #fff;
  position: relative;
  &:hover {
    border-color: #fb3a32;
  }

  .label {
    height: 22px;
    font-size: 16px;
    line-height: 22px;
    color: #1a1a1a;
    margin-bottom: 16px;
    .required {
      font-weight: 400;
      color: #ccc;
    }
  }

  .control-box {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 24px;
    color: #666;
    .icon {
      font-size: 16px;
      margin-right: 15px;
      cursor: pointer;
    }
    .switch-text {
      user-select: none;
      margin-right: 8px;
      font-size: 14px;
    }
    .switch {
      width: 28px;
    }
  }
}
</style>
