import BaseInfo from "./BaseInfo";
export default class SelectInfo extends BaseInfo {
  constructor() {
    super();

    this.type = "select";
    // 选项数组
    this.items = [];
    // 正确答案
    this.correctIndex = -1;

    this.replys = [];
  }
}
