<template>
  <div class="exam-execute-footer">
    <vh-button
      type="info"
      size="medium"
      plain
      round
      @click="setCurPage(--page)"
      v-show="page > 1"
      class="gray--button"
    >
      {{ configs.pageSize == 1 ? $t("exam.1004") : "上一页" }}
    </vh-button>
    <vh-button
      type="danger"
      size="medium"
      round
      :disabled="isDisabled"
      @click="setCurPage(++page)"
      v-show="page < totalPage"
    >
      {{ configs.pageSize == 1 ? $t("exam.1005") : "下一页" }}
    </vh-button>
    <vh-button
      type="danger"
      size="medium"
      round
      :disabled="isDisabled"
      @click="submit"
      id="exam_sdk_sub_btn"
      v-show="curPage == totalPage && answerType == 1"
    >
      {{ $t("exam.1006") }}
    </vh-button>
  </div>
</template>

<script>
export default {
  name: "ExamFooter",
  data() {
    return {};
  },
  props: {
    configs: {},
    // 是否做答模式
    answerType: {
      default: 1,
    },
    curPage: {
      default: 1,
    },
    totalPage: {
      default: 1,
    },
    isDisabled: {
      default: false,
    },
  },
  data() {
    return {
      page: this.curPage,
    };
  },
  watch: {
    curPage(value) {
      this.page = value;
      console.log("ans type...", this.answerType);
    },
  },
  inject: ["setCurPage", "submit"],
};
</script>

<style lang="scss" scoped>
.exam-execute-footer {
  box-sizing: border-box;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // z-index: 20;
  text-align: center;
  padding: 0 32px 24px 32px;
  background: #ffffff;
  width: 100%;
  button + button {
    margin-left: 12px;
  }
}
.vmp-exam-answer-wap .exam-execute-footer {
  padding: 8px 32px 29px 32px;
  padding-bottom: 29px;
  padding-bottom: calc(
    29px + constant(safe-area-inset-bottom)
  ); /* 兼容IOS<11.2 */
  padding-bottom: calc(29px + env(safe-area-inset-bottom)); /* 兼容IOS>11.2 */
}
</style>
