var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-text-ellipsis" },
    [
      _vm._t("before"),
      _c(
        "span",
        _vm._b(
          {
            class: _vm.textClass,
            style: _vm.textStyle,
            attrs: { content: _vm.text },
            on: { click: _vm.textClick },
          },
          "span",
          _vm.tooltipParams,
          false
        ),
        [
          _c(
            "span",
            { key: _vm.keyIndex, staticClass: "h-text-ellipsis-limit-text" },
            [_vm._v(_vm._s(_vm.text))]
          ),
          _c(
            "span",
            { staticClass: "h-text-ellipsis-more" },
            [_vm._v(_vm._s(_vm.more)), _vm._t("more")],
            2
          ),
        ]
      ),
      _vm._t("after"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }