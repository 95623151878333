<template>
  <div class="text-field">
    <vh-input
      v-model="item.replys"
      :class="{
        'form-field-inner-required': item.required,
      }"
      :placeholder="item.title"
    >
      <i v-if="item.required" slot="prefix" class="required">*</i>
    </vh-input>
  </div>
</template>

<script>
/**
 * @description 输入题
 */
export default {
  name: "a-text",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.text-field {
}
</style>
