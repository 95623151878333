<template>
  <div>
    <div class="vmp-exam-info-wap" :data-is-disabled="isDisabled">
      <!--
        题目类型：subject_type（图片题 img-text；文字题 text；）
        选择类型：type（单选 radio；多选 checkbox；）
        排列方式：option_layout（单列 1；多列 2；）
        图文排列方式：img_layout （上图下文：1；左图右文：2）
        -->
      <div
        class="vmp-exam-info--question"
        v-for="(item, index) in questionList"
        :key="index"
      >
        <component
          :item="item"
          :index="item.sortNum"
          :answerType="answerType"
          :preview="configs.preview"
          :key="item.id"
          :is="'a-' + item.type"
          class="vmp-exam-item-comp-wap"
          :ref="`comp_item_${item.id}`"
          @preview="preview"
        >
        </component>
      </div>
    </div>
    <exam-footer
      :configs="configs"
      :curPage="curPage"
      :totalPage="totalPage"
      :isDisabled="isDisabled"
      :answerType="answerType"
    />
  </div>
</template>
<script>
import ExamFooter from "../../components/footer.vue";
export default {
  name: "ExamInfoWap",
  components: {
    ExamFooter,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    configs: {},
    examServer: {},
    questionList: {
      default: [],
    },
    // 是否做答模式
    answerType: {
      default: 1,
    },
    curPage: {
      default: 1,
    },
    totalPage: {
      default: 1,
    },
  },
  computed: {
    // 是否允许点击下一题
    isDisabled() {
      let list = this.questionList || [];
      let selectArrays =
        list && list.filter((item) => item.replys && item.replys.length > 0);
      return this.answerType == 3 || (selectArrays && selectArrays.length > 0)
        ? false
        : true;
    },
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    preview(...args) {
      this.examServer.$emit("PREVIEW", ...args);
    },
  },
};
</script>
<style lang="scss" scoped>
/* 答题面板 */
.vmp-exam-info-wap {
  margin: 16px auto 0 auto;
}
</style>
