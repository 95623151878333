<template>
  <div
    class="q-title-input"
    @click="setEditState"
    :class="editState ? 'edit' : ''"
  >
    <innerInput
      v-show="editState"
      ref="input"
      v-model="inputVal"
      :maxlength="maxlength"
      @blur="editState = false"
    />
    <p v-show="!editState" class="q-title-text" v-text="inputVal" />
  </div>
</template>
<script>
import innerInput from "./innerInput.vue";
import vInputMixin from "@/vue/common/mixins/vInput";
// 自定义高度input
export default {
  name: "qTitleInput",
  mixins: [vInputMixin],
  components: {
    innerInput,
  },
  data() {
    return {
      editState: false, // 当前是在编辑状态
    };
  },
  methods: {
    async setEditState() {
      this.editState = true;
      await this.$nextTick();
      const input = this.$refs.input;
      input && input.autoFocus();
      input && input.resize();
    },
  },
};
</script>
<style lang="scss">
.q-title-input {
  word-break: break-all;
  min-height: 22px;
  padding: 8px 12px;
  border-radius: 4px;
  &:hover,
  &.edit {
    background: #f9f9f9;
    cursor: text;
  }
}
.q-title-text {
  font-size: 14px;
  line-height: 22px;
}
</style>
