import zhFront from "./modules/zh/frontEndPage";
import zhCode from "./modules/zh/code";
import enFront from "./modules/en/frontEndPage";
import enCode from "./modules/en/code";

export default {
  zh: {
    ...zhFront,
    ...zhCode,
  },
  en: {
    ...enFront,
    ...enCode,
  },
};
