import radio from "./RadioInfo";
import text from "./TextInfo";
import select from "./SelectInfo";
// import remark from "./RemarkInfo";
// import judge from "./JudgeInfo";
import date from "./DateInfo";
import checkbox from "./CheckBoxInfo";
import area from "./AreaInfo";
export default {
  radio,
  text,
  select,
  // remark,
  // judge,
  date,
  checkbox,
  area,
};
