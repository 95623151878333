import { getUUID } from "@/common/utils.js";
export default class BaseInfo {
  constructor(options) {
    this.id = "";
    this.keyId = getUUID();
    this.status = 1;
    // 是否必填
    this.required = false;
    // 题目类型
    this.type = "";
    // 题目标题
    this.title = "";
    // 题目图片连接
    this.imgUrl = "";

    // 是否需要校验
    this.verification = false;

    // 题目分数
    this.score = 0;

    //题目样式
    this.style = "";

    //扩展字段
    this.ext = "";

    // 扩展字段检索
    this.extension = {};

    // 题目解析
    this.analysis = "";
    //正确答案
    this.correct_answer = "";
    // 回答问题数据
    this.replys = "";

    // 创建时间
    this.createDate = "";

    // 题目是否修改
    this.isQuote = false;
    //题目详情
    this.detail = {};
  }
  //作答
  setAnswer(result) {
    // this.replys = result;
    this.answerCallback &&
      this.answerCallback({ id: this.id, type: this.type, answer: result });
  }
}
