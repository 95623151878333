<template>
  <div>
    <div class="title-input-container">
      <q-title-input v-model="data.title" />
    </div>
    <vh-input
      autocomplete="off"
      v-model.trim="data.value"
      show-word-limit
      type="text"
      class="intro-input"
      disabled
    />
  </div>
</template>

<script>
/**
 * @description 输入题
 */
import qTitleInput from "@/vue/components/common/qTitleInput.vue";
export default {
  name: "q-text",
  components: { qTitleInput },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.title-input-container {
  // padding: 8px 12px;
  position: relative;
  margin-bottom: 10px;
  &:hover {
    background: #f5f5f5;
    border-radius: 4px;
  }
}
.text-field-edit {
}
</style>
