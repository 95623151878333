var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPrivacyDialog
    ? _c(
        "div",
        { staticClass: "q-privacy" },
        [
          _c(
            "vh-dialog",
            {
              staticClass: "exam-privacy",
              attrs: {
                title: "隐私协议设置",
                visible: _vm.showPrivacyDialog,
                "append-to-body": "",
                width: "600px",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showPrivacyDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "privacy_box" },
                [
                  _c(
                    "div",
                    { staticClass: "flex_box" },
                    [
                      _c("span", { staticClass: "labelRight" }, [
                        _vm._v("隐私协议"),
                      ]),
                      _vm._v(" "),
                      _c("vh-input", {
                        attrs: { "max-length": 100 },
                        model: {
                          value: _vm.privacyContent,
                          callback: function ($$v) {
                            _vm.privacyContent = $$v
                          },
                          expression: "privacyContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.details, function (item, index) {
                    return _c("div", { key: index, staticClass: "posi" }, [
                      _c(
                        "div",
                        { staticClass: "flex_box" },
                        [
                          _c("span", { staticClass: "labelRight" }, [
                            _vm._v("协议名称"),
                          ]),
                          _vm._v(" "),
                          _c("vh-input", {
                            attrs: { "max-length": 100 },
                            on: {
                              change: (val) => (item.name = val),
                              blur: (el) => {
                                _vm.changeName(el, item.name, index)
                              },
                            },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "wid_i" }, [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.details.length == 1,
                                  expression: "details.length == 1",
                                },
                              ],
                              staticClass: "iconfont-v3 saasicon_fangda",
                              on: { click: _vm.addOption },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: index == 1,
                                  expression: "index == 1",
                                },
                              ],
                              staticClass: "iconfont-v3 saasicon_delete",
                              on: {
                                click: function ($event) {
                                  return _vm.delOption(item.name, index)
                                },
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.nameErrTip,
                              expression: "item.nameErrTip",
                            },
                          ],
                          staticClass: "nameErrTip",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.nameErrTip) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex_box" },
                        [
                          _c("span", { staticClass: "labelRight" }, [
                            _vm._v("跳转链接"),
                          ]),
                          _vm._v(" "),
                          _c("vh-input", {
                            staticClass: "tarea",
                            attrs: {
                              "max-length": 200,
                              type: "textarea",
                              autosize: { minRows: 2 },
                              resize: "none",
                              placeholder:
                                "请输入以http://或https://开头的链接",
                            },
                            on: {
                              blur: (el) => _vm.checkUrl(item, item.url, index),
                              change: (val) => (item.url = val),
                            },
                            model: {
                              value: item.url,
                              callback: function ($$v) {
                                _vm.$set(item, "url", $$v)
                              },
                              expression: "item.url",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "block_" }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      item.urlErrTip
                        ? _c("div", { staticClass: "urlErrTip" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.urlErrTip) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex_box" }, [
                    _c("span", { staticClass: "labelRight" }, [
                      _vm._v("预览效果"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "previewH",
                      domProps: { innerHTML: _vm._s(_vm.previewPrivacy) },
                    }),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "vh-button",
                    {
                      attrs: { type: "primary", size: "medium", round: "" },
                      on: { click: _vm.savePrivacy },
                    },
                    [_vm._v("确 定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "vh-button",
                    {
                      attrs: { size: "medium", round: "" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }