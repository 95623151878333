var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["examination-questions-panel", `client-${_vm.client}`] },
    [
      _vm.qData.detail.length
        ? [
            _c(
              "draggable",
              {
                ref: "drag",
                attrs: {
                  "scroll-sensitivity": 50,
                  "scroll-speed": 5,
                  "force-fallback": true,
                  "ghost-class": "ghost",
                  options: {
                    handle: ".sort-btn",
                    animation: 200,
                  },
                },
                on: {
                  update: _vm.moved,
                  start: function ($event) {
                    _vm.drag = true
                  },
                  end: function ($event) {
                    _vm.drag = false
                  },
                },
                model: {
                  value: _vm.qData.detail,
                  callback: function ($$v) {
                    _vm.$set(_vm.qData, "detail", $$v)
                  },
                  expression: "qData.detail",
                },
              },
              _vm._l(_vm.qData.detail, function (question, idx) {
                return _c(
                  "div",
                  {
                    key: question.keyId,
                    ref: "ques-item" + idx,
                    refInFor: true,
                    class: [
                      "question-item",
                      { "cur-edit": idx == _vm.qData.curIndex },
                    ],
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.qData.curIndex = idx
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "operation-bar" }, [
                      _c(
                        "span",
                        { staticClass: "tag", attrs: { size: "small" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.typenames[question.type]) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "btns" },
                        [
                          _c(
                            "vh-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "移动",
                                placement: "top",
                                "visible-arrow": false,
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "vh-form-iconfont vh-form-line-rank sort-btn",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "vh-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "复制",
                                placement: "top",
                                "visible-arrow": false,
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "vh-form-iconfont vh-form-line-copy",
                                class: { disabled: _vm.qCount == 100 },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.copyQuestion(idx)
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "vh-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "删除",
                                placement: "top",
                                "visible-arrow": false,
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "vh-form-iconfont vh-form-line-delete",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delQuestion(idx)
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "question-content" },
                      [
                        _c("span", { staticClass: "serial" }, [
                          _vm._v(_vm._s(`${idx + 1}.`)),
                        ]),
                        _vm._v(" "),
                        _c("q-" + question.type, {
                          tag: "component",
                          attrs: {
                            data: question,
                            multi: question.type === "multi",
                          },
                          on: {
                            "update:data": function ($event) {
                              question = $event
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "more-option" },
                          [
                            question.detail.list.length < 26
                              ? _c(
                                  "vh-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addOption(question)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "color-blue" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus-outline",
                                      }),
                                      _vm._v(" 添加选项\n              "),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "tip-style" }, [
                              _vm._v(" 选项最多支持26个 "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _c("div", { staticClass: "empty-tip" }, [_vm._m(0)]),
      _vm._v(" "),
      _c(
        "vh-button",
        {
          staticClass: "btn",
          attrs: {
            type: "primary",
            disabled: _vm.qData.detail.length === 0,
            round: "",
          },
          on: { click: _vm.checkSave },
        },
        [_vm._v("保存")]
      ),
      _vm._v(" "),
      _c(
        "vh-dialog",
        {
          attrs: {
            width: "400px",
            title: "提示",
            "show-close": false,
            "close-on-click-modal": false,
            visible: _vm.innerVisible,
            top: "30vh",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerVisible = $event
            },
          },
        },
        [
          _c("p", { staticClass: "tip-text" }, [
            _vm._v("保存同时共享至资料管理，便于其他活动使用？"),
          ]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "tip-check" },
            [
              _c(
                "vh-checkbox",
                {
                  model: {
                    value: _vm.isShare,
                    callback: function ($$v) {
                      _vm.isShare = $$v
                    },
                    expression: "isShare",
                  },
                },
                [_vm._v("共享到资料管理")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "confirm-btns" },
            [
              _c(
                "vh-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.saveForm },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "vh-button",
                {
                  attrs: { plain: "", round: "" },
                  on: {
                    click: function ($event) {
                      _vm.innerVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("i", { staticClass: "vh-form-iconfont vh-form-line-arrow1" }),
      _vm._v(" "),
      _c("span", { staticClass: "empty-text" }, [
        _vm._v(" 点击题型并设置正确答案和分数，创建题目"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }