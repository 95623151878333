var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "examination-pattern" }, [
    _c("label", { staticClass: "title" }, [_vm._v("题型")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pattern-panel" },
      _vm._l(_vm.questionTypes, function (item) {
        return _c(
          "vh-button",
          {
            key: item.name,
            class: ["pattern-item", { disabled: _vm.qCount == 100 }],
            attrs: { type: "text", plain: "" },
            on: {
              click: function ($event) {
                return _vm.addQuestion(item)
              },
            },
          },
          [
            _c("i", { class: ["vh-form-iconfont ", item.icon] }),
            _vm._v("\n      " + _vm._s(item.name) + "\n    "),
          ]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }